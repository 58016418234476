import { Box, Stack, Typography } from '@mui/material';
import { useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { toTitleCase } from 'shared/utils';
import { PieChart } from '@mui/x-charts';

export const AnalyticsApplicantStageCount = () => {

    const { applicantsList: applicantsData } = useAppSelector((state) => state.employer.dashboard.dashboardApplicants);
    const [pieChartData, setPieChartData] = useState<{ id: string; value: number; color: string }[]>([]);
    const [colors] = useState(['#3E82FC', '#50E3C2', '#FDC830', '#F56262', '#A461D8']); 

    useEffect(() => {
        if (applicantsData && applicantsData.length > 0) {
          const stageCounts: Record<string, number> = {};
    
          applicantsData.forEach((applicant) => {
            const stage = applicant.jobStage || "Unknown";
            if (!stageCounts[stage]) {
              stageCounts[stage] = 0;
            }
            stageCounts[stage]++;
          });
    
          const formattedData = Object.entries(stageCounts).map(([stage, count], index) => ({
            id: stage,
            value: count,
            color: colors[index % colors.length], 
          }));
          setPieChartData(formattedData);
        }
      }, [applicantsData, colors]);
    

    return (
        <Box>
            <Typography textAlign='center' variant="body2" align="center" gutterBottom>
                    Applicants by Job Stage
            </Typography>
            <Stack display="flex" direction="row" alignItems="center" justifyContent='center'>
                {/* Pie Chart */}
                <Box display="flex" alignContent="center" justifyContent='center' >
                    <PieChart
                    series={[
                        {
                        arcLabel: (item) => `${item.value}%`,
                        arcLabelMinAngle: 35,
                        arcLabelRadius: '60%',
                        data: pieChartData.map(({ id, value, color }) => ({ id, value, color })), 
                        innerRadius: 30,
                        outerRadius: 100,
                        paddingAngle: 1,
                        cornerRadius: 5,
                        startAngle: -45,
                        endAngle: 315,
                        },
                    ]}
                    width={300}
                    height={200}
                    sx={{
                        '& .MuiPieChart-label': {
                          fill: 'white', // Font color
                          fontSize: '10px', // Font size
                        },
                      }}
                    />
                </Box>
                <Stack spacing={1}>
                    <Typography variant="subtitle1" gutterBottom>
                    Legend
                    </Typography>
                    {pieChartData.map((item) => (
                        <Box key={item.id} display="flex" alignItems="center">
                            <Box
                            sx={{
                                width: 16,
                                height: 16,
                                backgroundColor: item.color,
                                borderRadius: '50%',
                                marginRight: 1,
                            }}
                            />
                                <Typography variant="body2">{toTitleCase(`${item.id}: ${item.value}`)}</Typography>
                        </Box>
                    ))}
                </Stack>
            </Stack>
        </Box>

    );
}