import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useCallback, useEffect } from 'react';
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { IBreadcrumbDictionary } from 'store/slices/app/app-model';
import { setCurrentBreadCrumb } from 'store/slices/app/breadcrumb-slice';
import {
    ApplicantDetailsBreadcrumbRegex, ApplicantsBreadcrumbRegex,
    CreateJobBreadcrumbRegex, DefaultBreadcrumb, IncompleteApplicantsBreadcrumbRegex
} from './BreadcrumbsConstants';
import { EmpBreadcrumbs } from './ShBreadcrumbs.styled';

export const EmployerBreadcrumbs = () => {

    const location = useLocation();
    const dispatch = useAppDispatch();
    const { jobId, applicantId } = useParams();
    const { currentBreadcrumb, availableBreadcrumbs, breadcrumbNavFrom,
        breadcrumbLabelRegistry } = useAppSelector((state) => state.app.breadcrumb);

    const getJobBreadcrumb = useCallback((jobPageUrl: string) => {
        if (jobPageUrl && breadcrumbLabelRegistry?.[`jobs_${jobId}_page`]) {
            jobPageUrl += `?jobId=${jobId}&page=${breadcrumbLabelRegistry?.[`jobs_${jobId}_page`]}&isExpand=${breadcrumbLabelRegistry?.[`jobs_${jobId}_isExpand`]}`;
        } else {
            jobPageUrl = "";
        }
        return jobPageUrl;
    }, [breadcrumbLabelRegistry, jobId]);

    const setBreadcrumbInApplicants = useCallback((bc: IBreadcrumbDictionary) => {
        let jobPageUrl = '';
        switch (breadcrumbNavFrom) {
            case 'active_jobs':
            case 'active_jobs_applicants':
                bc.breadcrumbs.push({ displayName: 'Active Jobs', href: '/employer/jobs/active', isActive: true });
                jobPageUrl = '/employer/jobs/active';
                break;
            case 'draft_jobs':
                bc.breadcrumbs.push({ displayName: 'Draft Jobs', href: '/employer/jobs/draft', isActive: true });
                break;
            case 'expired_jobs':
            case 'expired_jobs_applicants':
                bc.breadcrumbs.push({ displayName: 'Expired Jobs', href: '/employer/jobs/expired', isActive: true });
                jobPageUrl = '/employer/jobs/expired';
                break;
            default:
                break;
        }
        bc.breadcrumbs.push({
            displayName: (breadcrumbLabelRegistry && jobId) ? breadcrumbLabelRegistry[jobId] : `Job: ${jobId}`,
            href: getJobBreadcrumb(jobPageUrl), isActive: !!jobPageUrl
        });
        bc.breadcrumbs.push({ displayName: 'Applicants', href: '', isActive: false });
        dispatch(setCurrentBreadCrumb({ breadcrumb: bc }));
    }, [breadcrumbLabelRegistry, breadcrumbNavFrom, dispatch, getJobBreadcrumb, jobId]);

    const setBreadcrumbInApplicantDetails = useCallback((bc: IBreadcrumbDictionary) => {
        let jobPageUrl = '';
        switch (breadcrumbNavFrom) {
            case 'active_jobs':
            case 'active_jobs_applicants':
                bc.breadcrumbs.push({ displayName: 'Active Jobs', href: '/employer/jobs/active', isActive: true });
                jobPageUrl = '/employer/jobs/active';
                break;
            case 'draft_jobs':
                bc.breadcrumbs.push({ displayName: 'Draft Jobs', href: '/employer/jobs/draft', isActive: true });
                break;
            case 'expired_jobs':
            case 'expired_jobs_applicants':
                bc.breadcrumbs.push({ displayName: 'Expired Jobs', href: '/employer/jobs/expired', isActive: true });
                jobPageUrl = '/employer/jobs/expired';
                break;
            default:
                break;
        }
        bc.breadcrumbs.push({
            displayName: (breadcrumbLabelRegistry && jobId) ? breadcrumbLabelRegistry[jobId] : `Job: ${jobId}`,
            href: getJobBreadcrumb(jobPageUrl) ?? '', isActive: !!jobPageUrl
        });
        bc.breadcrumbs.push({
            displayName: 'Applicants',
            href: `/employer/applicants/${jobId}?page=${breadcrumbLabelRegistry?.[`applicants_${applicantId}_page`]}&applicantId=${applicantId}`, isActive: true
        });
        bc.breadcrumbs.push({
            displayName: (breadcrumbLabelRegistry && applicantId) ?
                breadcrumbLabelRegistry[applicantId] : `Applicant: ${applicantId}`, href: '', isActive: false
        });
        dispatch(setCurrentBreadCrumb({ breadcrumb: bc }));
    }, [applicantId, breadcrumbLabelRegistry, breadcrumbNavFrom, dispatch, getJobBreadcrumb, jobId]);

    const setBreadcrumbInJobCreateOrEdit = useCallback((bc: IBreadcrumbDictionary) => {
        switch (breadcrumbNavFrom) {
            case 'active_jobs':
                bc.breadcrumbs.push({ displayName: 'Active Jobs', href: '/employer/jobs/active', isActive: true });
                break;
            case 'draft_jobs':
                bc.breadcrumbs.push({ displayName: 'Draft Jobs', href: '/employer/jobs/draft', isActive: true });
                break;
            case 'expired_jobs':
                bc.breadcrumbs.push({ displayName: 'Expired Jobs', href: '/employer/jobs/expired', isActive: true });
                break;
            default:
                break;
        }
        bc.breadcrumbs.push({
            displayName: (breadcrumbLabelRegistry && jobId) ? breadcrumbLabelRegistry[jobId] : `Job: ${jobId}`,
            href: '', isActive: false
        });
        dispatch(setCurrentBreadCrumb({ breadcrumb: bc }));
    }, [breadcrumbLabelRegistry, breadcrumbNavFrom, dispatch, jobId]);

    const setBreadcrumbInIncompleteApplicants = useCallback((bc: IBreadcrumbDictionary) => {
        let jobPageUrl = '';
        switch (breadcrumbNavFrom) {
            case 'active_jobs':
                bc.breadcrumbs.push({ displayName: 'Active Jobs', href: '/employer/jobs/active', isActive: true });
                jobPageUrl = '/employer/jobs/active';
                break;
            case 'draft_jobs':
                bc.breadcrumbs.push({ displayName: 'Draft Jobs', href: '/employer/jobs/draft', isActive: true });
                break;
            case 'expired_jobs':
                bc.breadcrumbs.push({ displayName: 'Expired Jobs', href: '/employer/jobs/expired', isActive: true });
                jobPageUrl = '/employer/jobs/expired';
                break;
            default:
                break;
        }

        bc.breadcrumbs.push({
            displayName: (breadcrumbLabelRegistry && jobId) ? breadcrumbLabelRegistry[jobId] : `Job: ${jobId}`,
            href: getJobBreadcrumb(jobPageUrl), isActive: !!jobPageUrl
        });
        bc.breadcrumbs.push({
            displayName: "Incomplete Applicants",
            href: '', isActive: false
        });
        dispatch(setCurrentBreadCrumb({ breadcrumb: bc }));
    }, [breadcrumbLabelRegistry, breadcrumbNavFrom, dispatch, getJobBreadcrumb, jobId]);

    const buildBreadcrumb = useCallback(() => {
        // Regex matchers to test the url and identify the page context.
        const createJobTest = new RegExp(CreateJobBreadcrumbRegex);
        const applicantsTest = new RegExp(ApplicantsBreadcrumbRegex);
        const applicantDetailsTest = new RegExp(ApplicantDetailsBreadcrumbRegex);
        const incompleteApplicantDetailsTest = new RegExp(IncompleteApplicantsBreadcrumbRegex);

        // Final breadcrumb object to be pushed to slice, initialize with default path.
        let bc = {
            pathname: '/employer/dashboard',
            breadcrumbs: [
                {
                    displayName: 'Home',
                    href: '/employer/dashboard',
                    isActive: true
                }
            ]
        };

        if (jobId && applicantId) {
            if (location.pathname.includes(jobId) && location.pathname.includes(applicantId)) {
                // Navigated from Jobs list to applicants to applicant details. 
                if (applicantDetailsTest.test(location.pathname + location.hash)) {
                    setBreadcrumbInApplicantDetails(bc);
                }
            }
        } else if (jobId) {
            if (location.pathname.includes(jobId)) {
                // Navigated from Jobs list to create/edit job. 
                if (createJobTest.test(location.pathname)) {
                    setBreadcrumbInJobCreateOrEdit(bc);
                }

                // Navigated from Jobs list to applicants list page. 
                else if (applicantsTest.test(location.pathname)) {
                    setBreadcrumbInApplicants(bc);
                }

                // Navigated from Jobs list to incomplete applicants list page. 
                else if (incompleteApplicantDetailsTest.test(location.pathname)) {
                    setBreadcrumbInIncompleteApplicants(bc);
                }
            }
        } else {
            dispatch(setCurrentBreadCrumb({ breadcrumb: DefaultBreadcrumb }));
        }
    }, [applicantId, dispatch, jobId, location.pathname, location.hash, setBreadcrumbInApplicantDetails, setBreadcrumbInApplicants,
        setBreadcrumbInIncompleteApplicants, setBreadcrumbInJobCreateOrEdit]);

    useEffect(() => {
        if (location.pathname) {
            const breadcrumb = availableBreadcrumbs.find(aBc => aBc.pathname === location.pathname);
            if (breadcrumb) {
                dispatch(setCurrentBreadCrumb({ breadcrumb: breadcrumb }));
            } else {
                buildBreadcrumb();
            }
        }
    }, [availableBreadcrumbs, buildBreadcrumb, dispatch, location.pathname, breadcrumbNavFrom]);

    return (<>
        <EmpBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {currentBreadcrumb?.breadcrumbs.map((breadcrumb, indx) => (
                <ShMuiLink key={'bc_' + indx} underline='hover' variant='body2' component={RouterLink} to={breadcrumb.href}
                    className={breadcrumb.isActive ? '' : 'disabled'} noUnderline noBlueHighlight
                    tabIndex={breadcrumb.isActive ? 0 : -1}>
                    {breadcrumb.displayName}</ShMuiLink>
            ))}
        </EmpBreadcrumbs>
    </>);
};