import { ErrorPage } from "Modules/Core/RedirectPages/ErrorPage";
import { AccessLevels } from "Modules/Core/SettingsTs/AccountSettings/AccessLevels/AccessLevels";
import { AccountInfo } from "Modules/Core/SettingsTs/AccountSettings/AccountInfo";
import { AccountSettings } from "Modules/Core/SettingsTs/AccountSettings/AccountSettings";
import { CompanyInfo } from "Modules/Core/SettingsTs/AccountSettings/CompanyInfo";
import { Security } from "Modules/Core/SettingsTs/AccountSettings/Security/Security";
import { Navigate, Route, Routes } from "react-router-dom";

export const SettingsRouter = () => {

    return (<>
        <Routes>
            {/* Error 404 page */}
            <Route path="*" element={<ErrorPage />} />
            <Route path="/account" element={<AccountSettings />} >
                <Route path="/account" element={<Navigate replace to="/employer/settings/account/account-information" />} />
                <Route path="/account/account-information" element={<AccountInfo />} />
                <Route path="/account/company-information" element={<CompanyInfo />} />
                <Route path="/account/access-levels" element={<AccessLevels />} />
                <Route path="/account/security" element={<Security />} />
                <Route path="/account/branding" element={<></>} />
                <Route path="/account/integrations" element={<></>} />
            </Route>
            {/* <Route path="/tools" element={<ToolsAndAutomation />} >
                <Route path="/tools" element={<Navigate replace to="/employer/settings/tools" />} />
            </Route> */}
        </Routes>
    </>)
}