import ContactPageIcon from '@mui/icons-material/ContactPage';
import TodayIcon from '@mui/icons-material/Today';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Chip, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import { useAppSelector } from 'helpers/hooks';
import { ApplicantComment } from 'Modules/Core/Applicants/ApplicantsList/ApplicantComment';
import { ApplicantRating } from 'Modules/Core/Applicants/ApplicantsList/ApplicantRating';
import { ApplicantSummary } from 'Modules/Core/Applicants/ApplicantsList/ApplicantSummary';
import { StagesToggler } from 'Modules/Core/Applicants/ApplicantsList/StagesToggler';
import { IApplicantsComponentBase } from 'Modules/Core/Applicants/ApplicantsModel';
import moment from "moment";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeColorDivider } from 'shared/SharedStyles/ShDataDisplay';
import { ShCheckbox } from 'shared/SharedStyles/ShInputs';
import { ListPaperContainer } from 'shared/SharedStyles/ShSurfaces';
import { stringToColor } from 'shared/utils';
import { ResumeScoreGauge, getApplicantChipInfo } from '../ApplicantHelpers';

export const renderMatchChip = (matchType: string) => {
    const { chipColor, chipLabel, chipVariant, iconComponent } = getApplicantChipInfo(matchType);
    return <Chip size='small' label={chipLabel} color={chipColor} variant={chipVariant} icon={iconComponent} />;
};

export const ApplicantsListView = ({ applicantsList, applicantsSummary, selectedApplicants, selectApplicant,
    getApplicantSummary, changeStage, goToApplicantProfile, isAtsPurchased, downloadHiringGuide }: IApplicantsComponentBase) => {

    const location = useLocation();
    const { getApplicantsListStatus } = useAppSelector((state) => state.employer.applicants.applicantsList);

    useEffect(() => {
        if (getApplicantsListStatus === 'success') {
            const searchParams = new URLSearchParams(location.search);
            const applicantIdString = searchParams.get('applicantId');
            const applicantId = Number(applicantIdString) || undefined;
            const currRow = document.getElementById(`a_l_v_r_${applicantId}`);
            currRow?.setAttribute("tabindex", "0");
            currRow?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            currRow?.focus();
        }
    }, [getApplicantsListStatus, location.search]);

    return (<>
        <ListPaperContainer variant='outlined'>
            {applicantsList.map((applicant, index) => (
                <Accordion disableGutters variant='outlined' key={index} id={`a_l_v_r_${applicant.candidateEmployerJobId}`}
                    expanded={applicant.isExpanded === undefined ? false : applicant.isExpanded}
                    onChange={() => getApplicantSummary(applicant.candidateEmployerJobId)}>
                    <AccordionSummary aria-controls={`panel_${index}_control`} id={`panel_${index}_header`}>
                        <ShCheckbox id={applicant.candidateEmployerJobId?.toString()} checked={selectedApplicants?.includes(applicant.candidateEmployerJobId)}
                            size='small' onClick={(e) => e.stopPropagation()}
                            onChange={() => selectApplicant(applicant.candidateEmployerJobId)} />
                        <Avatar style={{ backgroundColor: stringToColor(`${applicant.fullName}`) }}
                            className='avatar' alt={applicant.fullName} src={`user-avatar-to-be-loaded-here`} />
                        <Stack>
                            <Stack flexDirection='row' mb={0.5} columnGap={{ xs: 0.5, sm: 1, md: 2 }} alignItems='center'>
                                <Typography className='name-blk' variant="body2" fontWeight='bold' >
                                    {applicant.fullName}
                                    <Tooltip title="View Applicant's details" onClick={e => e.stopPropagation()}>
                                        <Box component='span' onClick={(e) => { e.stopPropagation(); goToApplicantProfile(applicant) }}>
                                            <ContactPageIcon fontSize='small' color='primary' className='on-hover' />
                                        </Box>
                                    </Tooltip>
                                </Typography>
                                {!isAtsPurchased && <Typography component={'div'} variant="body2">{renderMatchChip(applicant.matchType)}</Typography>}
                                <ApplicantRating className='dsk-only-inline-flex' applicant={applicant} usedIn='list' />
                                <ResumeScoreGauge score={applicant.resumeScore || 0} />
                                <StagesToggler className='dsk-only-inline-flex' applicant={applicant}
                                    usedIn='list' changeStage={changeStage} />
                            </Stack>
                            {applicant.applicationCompletedAt &&
                                <Stack flexDirection='row'>
                                    <Typography variant="caption" className='info'><TodayIcon fontSize='small' />
                                        &nbsp;{moment(applicant.applicationCompletedAt).format("MMM DD, YYYY")}</Typography>
                                </Stack>}
                        </Stack>
                        <ApplicantComment applicantId={applicant.candidateEmployerJobId} />
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* Show loading and error feedback. */}
                        {applicantsSummary[applicant.candidateEmployerJobId]?.getSummaryStatus === 'pending' && <LinearProgress />}
                        {applicantsSummary[applicant.candidateEmployerJobId]?.getSummaryStatus === 'failed' &&
                            <Alert severity="error">{applicantsSummary[applicant.candidateEmployerJobId]?.getSummaryResponse ?? 'Something went wrong!'}</Alert>}
                        {applicantsSummary[applicant.candidateEmployerJobId]?.getSummaryStatus === 'success' &&
                            <>
                                {/* Mobile view section with Rating and Stage Toggle component*/}
                                <ThemeColorDivider />
                                <Stack display={{ xs: 'flex', sm: 'none', md: 'none' }} padding={1}
                                    justifyContent='space-between' rowGap={1}>
                                    <Box display='flex'>
                                        <Typography variant='subtitle2' marginRight={1}>Rating :</Typography>
                                        <ApplicantRating applicant={applicant} usedIn='list' />
                                    </Box>
                                    <Box display='flex'>
                                        <Typography variant='subtitle2' marginRight={1}>Stage :</Typography>
                                        <StagesToggler applicant={applicant} usedIn='list' changeStage={changeStage} />
                                    </Box>
                                </Stack>
                                <ThemeColorDivider />
                                <ApplicantSummary changeStage={changeStage} applicant={applicant}
                                    goToApplicantProfile={goToApplicantProfile} key={index} downloadHiringGuide={downloadHiringGuide}
                                    summary={applicantsSummary[applicant.candidateEmployerJobId]}
                                    applicantId={applicant.candidateEmployerJobId} isAtsPurchased={isAtsPurchased} />
                            </>}
                    </AccordionDetails>
                </Accordion>
            ))}
        </ListPaperContainer>
    </>);
};