import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAddJobToCart, IBundle, ICheckoutJobResponse, IInventoryBundle, IPaymentsState, IPurchaseJob, IPurchaseJobWithCoupon, IPurchaseResponse } from 'Modules/Core/CreateJob/CreateJobModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

const initialPaymentsState: IPaymentsState = {
    allBundles: [],
    allBaseBundles: [],
    allAddOnBundles: [],
    inventoryBundles: [],
    getAllBundlesResponse: '',
    getAllBundlesStatus: 'idle',
    getInventoryBundlesResponse: '',
    getInventoryBundlesStatus: 'idle',
    selectedBaseBundle: 0,
    selectedAddOnBundles: [],
    totalJobCost: 0
};

export const getAllBundles = createAsyncThunk<IBaseResponse<IBundle[]>, void, { rejectValue: IBaseResponse }>(
    "getAllBundles",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.ALL_BUNDLES}`)
            .then((response: AxiosResponse<IBaseResponse<IBundle[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getInventoryBundles = createAsyncThunk<IBaseResponse<IInventoryBundle[]>, void, { rejectValue: IBaseResponse }>(
    "getInventoryBundles",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.INVENTORY_BUNDLES}`)
            .then((response: AxiosResponse<IBaseResponse<IInventoryBundle[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const addJobToCart = createAsyncThunk<ICheckoutJobResponse, IAddJobToCart, { rejectValue: IBaseResponse }>(
    "addJobToCart",
    async ({ jobId, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.JOBS}/${jobId}/checkout`, payload)
            .then((response: AxiosResponse<ICheckoutJobResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const purchaseJob = createAsyncThunk<IPurchaseResponse, IPurchaseJob, { rejectValue: IBaseResponse }>(
    "purchaseJob",
    async ({ jobId, orderId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.JOBS}/${jobId}/purchase_orders/${orderId}`)
            .then((response: AxiosResponse<IPurchaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const purchaseJobWithCoupon = createAsyncThunk<IPurchaseResponse, IPurchaseJobWithCoupon, { rejectValue: IBaseResponse }>(
    "purchaseJobWithCoupon",
    async ({ jobId, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.JOBS}/${jobId}/coupon_checkout`, payload)
            .then((response: AxiosResponse<IPurchaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const createJobPaymentsSlice = createSlice({
    name: 'createJobPaymentsSlice',
    initialState: initialPaymentsState,
    reducers: {
        resetBundles: (state) => {
            state.getAllBundlesStatus = 'idle';
            state.getAllBundlesResponse = '';
            state.getInventoryBundlesStatus = 'idle';
            state.getInventoryBundlesResponse = '';
        },
        resetSelectedBundles: (state) => {
            state.selectedBaseBundle = 0;
            state.selectedAddOnBundles = [];
        },
        resetAddToCart: (state) => {
            state.addJobToCartStatus = 'idle';
            state.addJobToCartResponse = undefined;
        },
        resetPurchase: (state) => {
            state.purchaseJobStatus = 'idle';
            state.purchaseJobResponse = '';
            state.purchaseUrl = undefined;
            state.allBundles = [];
        },
        resetPurchaseWithCoupon: (state) => {
            state.purchaseJobWithCouponStatus = 'idle';
            state.purchaseJobWithCouponResponse = '';
        },
        setSelectedBaseBundle: (state, action: PayloadAction<number>) => {
            state.selectedBaseBundle = action.payload;
        },
        setSelectedAddOnBundles: (state, action: PayloadAction<number[]>) => {
            state.selectedAddOnBundles = action.payload;
        },
        setTotalJobCost: (state, action: PayloadAction<number>) => {
            state.totalJobCost = action.payload;
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialPaymentsState;
        });
        // get all bundles
        builder.addCase(getAllBundles.pending, (state) => {
            state.getAllBundlesStatus = 'pending'
        });
        builder.addCase(getAllBundles.fulfilled, (state, action) => {
            state.getAllBundlesStatus = 'success';
            state.allBundles = action.payload.data;
            state.allBaseBundles = state.allBundles.filter(ab => ab.kind === 'base')?.sort((a, b) => a.order - b.order);
            state.allAddOnBundles = state.allBundles.filter(ab => ab.kind === 'addon')?.sort((a, b) => a.order - b.order);
        });
        builder.addCase(getAllBundles.rejected, (state, action) => {
            state.getAllBundlesStatus = 'failed';
            state.getAllBundlesResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get inventory bundles
        builder.addCase(getInventoryBundles.pending, (state) => {
            state.getInventoryBundlesStatus = 'pending'
        });
        builder.addCase(getInventoryBundles.fulfilled, (state, action) => {
            state.getInventoryBundlesStatus = 'success';
            state.inventoryBundles = action.payload.data;
        });
        builder.addCase(getInventoryBundles.rejected, (state, action) => {
            state.getInventoryBundlesStatus = 'failed';
            state.getInventoryBundlesResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // add job to cart
        builder.addCase(addJobToCart.pending, (state) => {
            state.addJobToCartStatus = 'pending'
        });
        builder.addCase(addJobToCart.fulfilled, (state, action) => {
            state.addJobToCartStatus = 'success';
            state.addJobToCartResponse = action.payload?.message;
            state.orderId = action.payload.order_id;
        });
        builder.addCase(addJobToCart.rejected, (state, action) => {
            state.addJobToCartStatus = 'failed';
            state.addJobToCartResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // purchase job
        builder.addCase(purchaseJob.pending, (state) => {
            state.purchaseJobStatus = 'pending'
        });
        builder.addCase(purchaseJob.fulfilled, (state, action) => {
            const { purchase_complete, message, url } = action.payload;
            if (purchase_complete) {
                state.purchaseJobStatus = 'success';
            } else {
                state.purchaseJobStatus = 'stripe_payment_required';
                state.purchaseUrl = url;
            }
            state.purchaseJobResponse = message;
        });
        builder.addCase(purchaseJob.rejected, (state, action) => {
            state.purchaseJobStatus = 'failed';
            state.purchaseJobResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // purchase job with coupon
        builder.addCase(purchaseJobWithCoupon.pending, (state) => {
            state.purchaseJobWithCouponStatus = 'pending';
        });
        builder.addCase(purchaseJobWithCoupon.fulfilled, (state, action) => {
            state.purchaseJobWithCouponStatus = 'success';
            state.purchaseJobWithCouponResponse = action.payload.message;
        });
        builder.addCase(purchaseJobWithCoupon.rejected, (state, action) => {
            state.purchaseJobWithCouponStatus = 'failed';
            state.purchaseJobWithCouponResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { setSelectedBaseBundle, setSelectedAddOnBundles, setTotalJobCost, resetBundles, resetSelectedBundles, resetAddToCart,
    resetPurchase, resetPurchaseWithCoupon } = createJobPaymentsSlice.actions;
export default createJobPaymentsSlice;
