import { useAppSelector } from "helpers/hooks";
import { Bundles } from "Modules/Core/CreateJob/Bundles";
import { CreateJob } from "Modules/Core/CreateJob/CreateJob";
import { JobDetails } from "Modules/Core/CreateJob/JobDetails";
import { JobPreview } from "Modules/Core/CreateJob/JobPreview";
import { JobTitle } from "Modules/Core/CreateJob/JobTitle";
import { Payment } from "Modules/Core/CreateJob/Payment";
import { PaymentRedirect } from "Modules/Core/CreateJob/PaymentRedirect";
import { TemplateJobSelect } from "Modules/Core/CreateJob/TemplateJobSelect";
import { WelcomeCreateJob } from "Modules/Core/CreateJob/WelcomeCreateJob";
import { ErrorPage } from "Modules/Core/RedirectPages/ErrorPage";
import { Navigate, Route, Routes } from "react-router-dom";

export const CreateJobRouter = () => {

    const { employerDetails } = useAppSelector(state => state.employer.employerDetails);

    return (<>
        <Routes>
            {/* Error 404 page */}
            <Route path="*" element={<ErrorPage />} />
            <Route path="/welcome" element={<WelcomeCreateJob />} />
            <Route path="/" element={<CreateJob />} >
                {employerDetails?.has_template_jobs ?
                    <Route path="/new" element={<Navigate replace to="/employer/job/new/template-job/choose-type" />} />
                    :
                    <Route path="/new" element={<Navigate replace to="/employer/job/new/title" />} />
                }
                <Route path="/:jobId" element={<Navigate replace to="/employer/job/:jobId/title" />} />
                <Route path="/:jobId/title" element={<JobTitle />} />
                <Route path="/:jobId/details" element={<JobDetails />} />
                <Route path="/:jobId/preview" element={<JobPreview />} />
                {/* <Route path="/:jobId/eeoQuestionnaire" element={<EEOJobPreview />}  /> */}
                <Route path="/:jobId/bundles" element={<Bundles />} />
                <Route path="/:jobId/payment" element={<Payment />} >
                    <Route path="/:jobId/payment/success" element={<PaymentRedirect />} />
                    <Route path="/:jobId/payment/failed" element={<PaymentRedirect />} />
                </Route>
            </Route>
            {employerDetails?.has_template_jobs &&
                <>
                    <Route path="/new/template-job/choose-type" element={<TemplateJobSelect />} />
                </>}
        </Routes>
    </>)
}