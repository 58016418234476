export const msalConfig = {
    auth: {
      clientId: "cbd7a741-7573-493b-b169-a5a062e40ea5", // Replace with your Azure AD app's Client ID
      authority: "https://login.microsoftonline.com/common",
      redirectUri: "https://smoothhiring.com/",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  };
  
  export const loginRequest = {
    scopes: ["User.Read", "Mail.Read"],
  };