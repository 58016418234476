import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createJobDetailsSlice from 'store/slices/employer/create-job/create-job-details-slice';
import createJobPaymentsSlice from 'store/slices/employer/create-job/create-job-payments-slice';
import createJobPreviewSlice from 'store/slices/employer/create-job/create-job-preview-slice';
import createJobSharedSlice from 'store/slices/employer/create-job/create-job-shared-slices';

const createJobPersistConfig = {
    key: 'createJob',
    storage,
    whitelist: ['companyInfo', 'jobDetails', 'jobTitleInfo', 'currentJobCreationFlow', 'isJobIdWithTemplateCreated',
        'isDetailsEnabled', 'isPreviewEnabled', 'isBundlesEnabled', 'isPaymentEnabled']
};

const paymentPersistConfig = {
    key: 'createJobPayment',
    storage,
    whitelist: ['selectedBundles', 'allBundles', 'allBaseBundles', 'allAddOnBundles', 'orderId', 'totalJobCost']
};

export const createJobReducer = combineReducers({
    createJobDetails: createJobDetailsSlice.reducer,
    createJobPayments: persistReducer(paymentPersistConfig, createJobPaymentsSlice.reducer),
    createJobPreview: createJobPreviewSlice.reducer,
    createJobShared: persistReducer(createJobPersistConfig, createJobSharedSlice.reducer),
});
