import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
    Avatar, AvatarProps, Chip, ChipProps, Divider, Stack, Table, TableCell, TableContainer, TableProps, TableRow, TableRowProps, Typography,
    TypographyProps
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ResourceTypography } from 'Modules/Marketing/Resources/Resources.styled';
import {
    BorderColorDark, BorderColorLight, DarkModeBackground,
    MUIGrey,
    PaperBorderRadius, PrimaryThemeColorLight, WhiteColor
} from 'shared/SharedStyles/styleConstants';

type ColorType = 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'transparent';

interface IShAvatarProps extends AvatarProps {
    color?: ColorType,
    height?: number | string,
    width?: number | string,
    minWidth?: number | string,
    backgroundColor?: string,
    svgSize?: string
}

export const ShAvatar = styled((props: IShAvatarProps) => <Avatar {...props} />, {
    shouldForwardProp: (prop) => prop !== "backgroundColor" && prop !== "minWidth" && prop !== "svgSize"
})(
    ({ theme, color, width = 'unset', height = 'unset', backgroundColor, minWidth = 'unset', svgSize = '24px' }) => ({
        [theme.breakpoints.up('xs')]: {
            '&.dashboard-user-avatar': {
                height: '55px',
                width: '55px'
            }
        },
        [theme.breakpoints.up('sm')]: {
            '&.dashboard-user-avatar': {
                height: '60px',
                width: '60px'
            }
        },
        [theme.breakpoints.up('md')]: {
            '&.dashboard-user-avatar': {
                height: '65px',
                width: '65px'
            }
        },
        [theme.breakpoints.up('lg')]: {
            '&.dashboard-user-avatar': {
                height: '65px',
                width: '65px'
            }
        },
        backgroundColor: backgroundColor ? backgroundColor : color === 'primary' ? theme.palette.primary.main :
            color === 'secondary' ? theme.palette.secondary.main : color === 'success' ?
                theme.palette.success.main : color === 'warning' ? theme.palette.warning.main : color === 'transparent' ?
                    'transparent' : theme.palette.error.main,

        height: height,
        width: width,
        minWidth: minWidth,
        '& svg': {
            fontSize: svgSize
        }
    })
);

export const ThemeColorDivider = styled(Divider)(({ theme }) => ({
    borderColor: theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark
}));

interface IShTable extends TableProps {
    columnBorders?: boolean
}

// Table view styles
export const ShTableContainer = styled(TableContainer)(({ theme }) => ({
    overflow: 'auto',
    flex: 1,
    border: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
    borderRadius: PaperBorderRadius,
    minWidth: 300
}));

export const ShTable = styled((props: IShTable) => <Table {...props} />, {
    shouldForwardProp: (prop) => prop !== "columnBorders"
})(({ theme, columnBorders = false }) => ({
    borderCollapse: 'separate',
    [theme.breakpoints.up('xs')]: {
        [`& td,th`]: {
            padding: '2px'
        },
    },
    [theme.breakpoints.up('sm')]: {
        [`& td,th`]: {
            padding: '4px 8px'
        },
    },
    [theme.breakpoints.up('md')]: {
        [`& td,th`]: {
            padding: '6px 10px'
        },
    },
    [theme.breakpoints.up('lg')]: {
        [`& td,th`]: {
            padding: '6px 14px'
        },
    },
    [theme.breakpoints.up('xl')]: {
        [`& td,th`]: {
            padding: '6px 16px'
        },
    },
    '& tr': {
        '& td,th': {
            "&:not(:last-of-type)": {
                borderBottom: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
                borderRight: columnBorders ? `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}` : 'none'
            }
        }
    }
}));

interface IShTableRow extends TableRowProps {
    cursor: "pointer" | "default";
}

export const ShTableRow = styled((props: IShTableRow) => <TableRow {...props} />)(
    ({ theme, cursor }) => ({
        cursor: cursor,
        backgroundColor: theme.palette.mode === 'light' ? WhiteColor : DarkModeBackground,
        '& th': {
            fontWeight: 500,
            position: 'sticky',
            top: 0,
            zIndex: 2,
            backgroundColor: theme.palette.mode === 'light' ? WhiteColor : DarkModeBackground,
            '&.select-header': {
                left: 0,
                zIndex: 3
            }
        },
        '& .more-btn': {
            opacity: 0,
        },
        '&:hover': {
            '& .on-hover': {
                visibility: 'visible',
            },
            '& .more-btn': {
                opacity: 1,
            }
        },
        '& .on-hover': {
            visibility: 'hidden', // Initially hidden
        },
        '&:hover .on-hover': {
            visibility: 'visible', // Visible on hover
        },
        '&.is-actions-open': {
            backgroundColor: PrimaryThemeColorLight
        },
        '&:focus': {
            backgroundColor: '#ebebeb'
        },
        '& .MuiTableCell-paddingNone': {
            padding: 0
        }
    })
);

export const RowSelectCell = styled(TableCell)(({ theme }) => ({
    width: '2%',
    paddingRight: '0px',
    textAlign: 'center',
    position: 'sticky',
    left: 0,
    zIndex: 1,
    backgroundColor: 'inherit'
}));

export const CollapsibleTableRow = styled(TableRow)(
    ({ theme }) => ({
        backgroundColor: theme.palette.mode === 'light' ? WhiteColor : DarkModeBackground,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'table-row',
        },
        [theme.breakpoints.up('md')]: {
            display: 'table-row',
        },
        '& .MuiTableCell-root': {
            padding: 0,
            border: 'none'
        }
    })
);

interface IUnderlinedText extends TypographyProps {
    underlineType?: 'default' | 'border';
    borderBottomWidth?: number,
    borderType?: 'dotted' | 'double' | 'solid',
    borderColor?: string
}

export const UnderlinedText = styled((props: IUnderlinedText) => <Typography {...props} />, {
    shouldForwardProp: (prop) => prop !== "underlineType" && prop !== "borderBottomWidth"
})(
    ({ theme, underlineType = 'default', borderBottomWidth = 1, borderType = 'solid',
        borderColor = theme.palette.text.primary }) => (
        {
            textDecoration: underlineType === 'default' ? 'underline' : 'none',
            borderBottom: underlineType === 'border' ? `${borderBottomWidth}px ${borderType} ${borderColor}` : 'none'
        }
    ));

export const GutterText = styled(Typography)(({ theme }) => (
    {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
));

export const LabelWithBadge = styled(Typography)(
    ({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiTypography-caption': {
            backgroundColor: theme.palette.getContrastText(theme.palette.primary.main),
            color: theme.palette.primary.main,
            padding: theme.spacing(0.5),
            height: '20px',
            minWidth: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '20px'
        }
    })
);

export const ShBullets = styled('ul')(() => (
    {
        paddingInlineStart: '20px',
        color: MUIGrey,
        margin: 'unset'
    }
));

interface ShCheckmarkProps {
    items: string[];
}

export const ShCheckmark: React.FC<ShCheckmarkProps> = ({ items }) => {
    return <>
        {items.map((item, index) => {
            const trimmedItem = item.trim();
            const isNegative = trimmedItem.startsWith('~');
            const displayText = trimmedItem.replace(/^[-~]/, '').trim(); // Removin leading - or ~

            return (
                displayText && (
                    <li key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '4px', }}>
                        <Stack direction='row' spacing={1}>
                            {isNegative ? (
                                <CloseIcon color="error" fontSize="small" /> // Red X for items starting with ~
                            ) : (
                                <CheckIcon color="success" fontSize="small" /> // Green check for items starting with -
                            )}
                            <ResourceTypography variant="caption" gutterBottom>
                                {displayText}
                            </ResourceTypography>
                        </Stack>
                    </li>
                )
            );
        })}
    </>;
};


interface ISquareChipProps extends ChipProps {
    bgColor?: string;
    textColor?: string;
    borderRadius?: string;
    customSize?: 'xs' | 'small' | 'medium' | 'large';
}

// export const ShChip = styled((props: ISquareChipProps) => <Chip {...props} />, {
//     shouldForwardProp: (prop) => prop !== "bgColor" && prop !== "textColor" && prop !== "borderRadius"
// })(
//     ({ theme, bgColor, textColor, borderRadius }) => (
//         {
//             backgroundColor: bgColor,
//             color: textColor,
//             borderRadius: borderRadius || 4,
//         }
//     ));

export const ShChip = styled((props: ISquareChipProps) => {
    const { customSize, ...restProps } = props;
    return <Chip {...restProps} />;
}, {
    shouldForwardProp: (prop) => prop !== "bgColor" && prop !== "textColor" && prop !== "borderRadius" && prop !== "customSize"
})(
    ({ bgColor, textColor, borderRadius, customSize }) => {
        let padding, fontSize;

        switch (customSize) {
            case 'xs':
                padding = '1px 1px';
                fontSize = '0.625rem';
                break;
            case 'small':
                padding = '4px 8px';
                fontSize = '0.75rem';
                break;
            case 'medium':
                padding = '6px 12px';
                fontSize = '0.875rem';
                break;
            case 'large':
                padding = '8px 16px';
                fontSize = '1rem';
                break;
            default:
                padding = '6px 12px';
                fontSize = '0.875rem';
        }

        return {
            backgroundColor: bgColor,
            color: textColor,
            borderRadius: borderRadius || '4px',
            padding: padding,
            fontSize: fontSize,
        };
    }
);