import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ConstructionIcon from '@mui/icons-material/Construction';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import { Box, Divider, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { DefaultRoleForRAC, RoleAccessControlMap } from 'Modules/Core/AuthV2/AuthConstants';
import { TFeatures } from 'Modules/Core/AuthV2/AuthModel';
import { ShCreateJobIcon, ShDashboardIcon, ShMyInterviewsIcon, ShMyJobsIcon } from 'assets/Icons';
import { useAppSelector } from 'helpers/hooks';
import { useDispatch } from 'react-redux';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { ISideNav } from 'shared/SharedModels';
import { LeftNavButtonStyled } from 'shared/SharedStyles/ShInputs';
import { MobileOnlyStack, ShIconWrapper, SideNavLinksStack } from 'shared/SharedStyles/ShLayouts';
import { ShIconPrimary, ShIconSecondary } from 'shared/SharedStyles/styleConstants';
import { togglePricingDialog } from 'store/slices/app/app-info-slice';
import { CoreSideNavLinks } from './AppLayoutConstants';

export const SideNav = ({ isMiniDrawer, toggleDrawer }: ISideNav) => {
    const dispatch = useDispatch();
    const { accountAccess } = useAppSelector((state) => state.auth.auth);
    const theme = useTheme();

    const renderSideNavLinkIcon = (feature: TFeatures, isDisabled: boolean) => {
        switch (feature) {
            case 'Dashboard':
                return <ShDashboardIcon primaryColor={isDisabled ? theme.palette.grey[500] : ShIconPrimary}
                    secondaryColor={isDisabled ? theme.palette.grey[500] : ShIconSecondary} />;
            case 'Create Job':
                return <ShCreateJobIcon primaryColor={isDisabled ? theme.palette.grey[500] : ShIconPrimary}
                    secondaryColor={isDisabled ? theme.palette.grey[500] : ShIconSecondary} />;
            case 'View Jobs':
                return <ShMyJobsIcon primaryColor={isDisabled ? theme.palette.grey[500] : ShIconPrimary}
                    secondaryColor={isDisabled ? theme.palette.grey[500] : ShIconSecondary} />;
            case 'Interviews':
                return <ShMyInterviewsIcon primaryColor={isDisabled ? theme.palette.grey[500] : ShIconPrimary}
                    secondaryColor={isDisabled ? theme.palette.grey[500] : ShIconSecondary} />;
            case 'Analytics':
                return <QueryStatsOutlinedIcon style={{ color: isDisabled ? theme.palette.grey[500] : ShIconPrimary, transform: 'scale(1.3)' }} />;
            case 'Calendar':
                return <CalendarMonthIcon style={{ color: isDisabled ? theme.palette.grey[500] : ShIconPrimary, transform: 'scale(1.3)' }} />;
            case 'Resources':
                return <LibraryBooksIcon style={{ color: isDisabled ? theme.palette.grey[500] : ShIconPrimary, transform: 'scale(1.2)' }} />;
            case 'Tools And Automation':
                return <ConstructionIcon style={{ color: isDisabled ? theme.palette.grey[500] : ShIconPrimary, transform: 'scale(1.2)' }} />;
            default:
                break;
        }
    }

    const LabelWithDynamicTooltip = (feature: TFeatures, label: string, isDisabled: boolean) => {
        return (<>
            {isMiniDrawer ?
                <Tooltip placement='right-end' title={label} arrow>
                    <ShIconWrapper>{renderSideNavLinkIcon(feature, isDisabled)}</ShIconWrapper>
                </Tooltip> :
                <ShIconWrapper>{renderSideNavLinkIcon(feature, isDisabled)}</ShIconWrapper>}
            {!isMiniDrawer && <Typography variant='body2'>{label}</Typography>}
        </>)
    };

    return (<>
        {/* <UserAvatarBio isMiniDrawer={isMiniDrawer} />
        <Divider /> */}
        <Stack height='100%' display='flex' justifyContent='space-between'>
            <Box>
                <SideNavLinksStack>
                    {CoreSideNavLinks.map((link, index) => (
                        RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC]?.featureMap
                            ?.[link.feature]?.visibility !== 'hidden' &&
                        <NavLink to={link.redirectTo} aria-label={link.label} key={index}
                            className={({ isActive }) => isActive ? 'active' :
                                RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC]?.featureMap
                                    ?.[link.feature]?.visibility === 'disabled' ? 'disabled' : ''} onClick={toggleDrawer}>
                            {LabelWithDynamicTooltip(link.feature, link.label,
                                RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC]?.featureMap
                                    ?.[link.feature]?.visibility === 'disabled')}
                        </NavLink>
                    ))}
                </SideNavLinksStack>
                <MobileOnlyStack>
                    <Divider />
                    <LeftNavButtonStyled startIcon={<MonetizationOnOutlinedIcon />}
                        onClick={() => dispatch(togglePricingDialog())}>Pricing</LeftNavButtonStyled>
                    <LeftNavButtonStyled startIcon={<HelpOutlineOutlinedIcon />}
                        component={RouterLink} to='/employer/help'>Help</LeftNavButtonStyled>
                </MobileOnlyStack>
            </Box>
        </Stack>
    </>
    );
};
