import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Stack, Tab, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useNotification } from "Modules/Core/Notification";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShTabs } from "shared/SharedStyles/ShNavigation";
import { downloadApplicantHiringGuide, downloadCandidateProfile, resetDownloadApplicantHiringGuide, resetDownloadApplicantProfile } from "store/slices/employer/applicants/applicant-actions-slice";
import { IApplicant } from "../ApplicantsModel";
import { ApplicantComments } from "./ApplicantComments";
import { ApplicantProfile } from "./ApplicantProfile";
import { ApplicantDetailsSection, ApplicantPathEnum } from "./ApplicantProfileModel";
import { ApplicantQuestions } from "./ApplicantQuestions";
import { ApplicantResume } from "./ApplicantResume";

export const ApplicantProfileWrapper = () => {

    const notification = useNotification();
    const dispatch = useAppDispatch();
    const [currentSection, setCurrentSection] = useState<ApplicantDetailsSection>('profile');
    const { applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const { downloadingGuideStatus, downloadingGuideResponse, downloadCandidateProfileResponse, downloadCandidateProfileStatus } = useAppSelector(state => state.employer.applicants.applicantActions);
    // All applicant info UI element references which will be added to the intersection observer.
    const sectionRefs = useRef<{ [key: string]: Element | null }>({});
    const navigate = useNavigate();
    const { jobId } = useParams();

    // Intersection Observer setup
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '50px',
            threshold: 0.1,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const sectionId = entry.target.id as ApplicantDetailsSection;
                    setCurrentSection(sectionId);
                    window.history.replaceState(null, '', `#${sectionId}`);
                }
            });
        }, options);

        Object.values(sectionRefs.current).forEach(ref => {
            if (ref) {
                observer.observe(ref);
            }
        });
    }, []);

    const downloadHiringGuide = (applicant: IApplicant) => {
        dispatch(downloadApplicantHiringGuide({ applicantId: applicant.candidateEmployerJobId, applicantName: applicant.fullName }));
    }

    const dispatchDownloadCandidateProfile = (applicant: IApplicant) => {
        dispatch(downloadCandidateProfile({ applicantId: applicant.candidateEmployerJobId, applicantName: applicant.fullName }));
    }

    // Show snackbar notifications on download list as csv.
    useEffect(() => {
        if (downloadingGuideStatus === 'success' || downloadingGuideStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: downloadingGuideStatus === 'success' ? 'success' : 'error',
                message: downloadingGuideResponse ?? 'Download successful'
            });
            dispatch(resetDownloadApplicantHiringGuide());
        }
    }, [dispatch, downloadingGuideResponse, downloadingGuideStatus, notification]);

    useEffect(() => {
        if (downloadCandidateProfileStatus === 'success' || downloadCandidateProfileStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: downloadCandidateProfileStatus === 'success' ? 'success' : 'error',
                message: downloadCandidateProfileResponse ?? 'Download successful'
            });
            dispatch(resetDownloadApplicantProfile());
        }
    }, [dispatch, downloadCandidateProfileResponse, downloadCandidateProfileStatus, notification]);


    return (<>
        <Stack direction='row' alignItems='center' justifyContent='space-between'
            rowGap={1} flexWrap='wrap' paddingX={{ xs: 2, sm: 3, md: 4, lg: 4 }} marginBottom={1}>
            <ShTabs className="applicant-tabs" aria-label="Applicant Tabs" variant="scrollable" scrollButtons='auto'
                allowScrollButtonsMobile value={currentSection} onChange={(e, nV) => setCurrentSection(nV as ApplicantDetailsSection)}>
                <Tab label="Profile" value={ApplicantPathEnum.Profile} href="#profile" />
                <Tab label="Resume" value={ApplicantPathEnum.Resume} href="#resume" />
                {/* Show Questions tab only if atsPurchased is false */}
                {applicantDetails && !applicantDetails?.atsPurchased &&
                    <Tab label="Questions" value={ApplicantPathEnum.Questions} href="#questions" />
                }
                <Tab label="Comments" value={ApplicantPathEnum.Comments} href="#comments" />
                {/* <Tab label="Stage" component={Link} value={ApplicantPathEnum.Stage} to={`/employer/job/${jobId}/applicant/${applicantId}/stage`} /> */}
            </ShTabs>
            <Stack direction="row" spacing={1}>
                <ShButton disableElevation
                    onClick={(e) => {
                        e.stopPropagation();
                        applicantDetails && dispatchDownloadCandidateProfile(applicantDetails);
                    }}
                    variant="outlined"
                    startIcon={
                        downloadCandidateProfileStatus === 'pending' ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            <DownloadIcon fontSize="medium" />
                        )
                    }
                    disabled={downloadCandidateProfileStatus === 'pending'}
                >
                    {downloadCandidateProfileStatus === 'pending'
                        ? 'Downloading...'
                        : 'Download Profile'}
                </ShButton>
                {!applicantDetails?.atsPurchased &&
                    <ShButton
                        startIcon={
                            downloadingGuideStatus === 'pending' ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <DownloadIcon fontSize="medium" />
                            )
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            applicantDetails && downloadHiringGuide(applicantDetails);
                        }}
                        variant="outlined"
                        disabled={downloadingGuideStatus === 'pending'}
                        disableElevation
                        color="primary"
                        size="small"
                    >
                        {downloadingGuideStatus === 'pending'
                            ? 'Downloading...'
                            : 'Download Hiring Guide'}
                    </ShButton>}

            </Stack>
        </Stack>
        <Box padding={{ xs: 2, sm: 3, md: 4, lg: 4 }} paddingTop='8px !important' flex={1}
            overflow='auto' id='applicantSectionsContainer' position='relative'>
            <Box display="flex" alignItems="center">
                <ShButton startIcon={<ArrowBackIcon />} variant="outlined"
                    onClick={() => navigate(`/employer/applicants/${jobId}`)}>
                    Back
                </ShButton>
            </Box>
            <Accordion defaultExpanded id="profile" ref={(el) => sectionRefs.current['profile'] = el}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="preview-content">
                    <Typography variant='h6'>Profile</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ApplicantProfile />
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded id="resume" ref={(el) => sectionRefs.current['resume'] = el}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="preview-content">
                    <Typography variant='h6'>Resume</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ApplicantResume />
                </AccordionDetails>
            </Accordion>
            {applicantDetails && !applicantDetails.atsPurchased && (
                <Accordion defaultExpanded id="questions" ref={(el) => sectionRefs.current['questions'] = el}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="preview-content">
                        <Typography variant='h6'>Questions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <ApplicantQuestions />

                    </AccordionDetails>
                </Accordion>)}

            <Accordion defaultExpanded id="comments" ref={(el) => sectionRefs.current['comments'] = el}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="preview-content">
                    <Typography variant='h6'>Comments</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ApplicantComments />
                </AccordionDetails>
            </Accordion>
        </Box>
    </>);
}