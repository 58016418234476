import { TAccountType } from "store/slices/auth-v2/auth-v2-model";
import { EEOQutionaareItem, ISocialProvider, TFeaturesMap, TRoleAccessMap, TSocialProvidersMap } from "./AuthModel";

export const EmployerLoginRedirect = '/employer/dashboard';
export const EmployerLogoutRedirect = '/accounts/login';
export const AdminLoginRedirect = "/admin";
export const AdminLogoutRedirect = "/accounts/login";
export const CandidateLoginRedirect = "/candidate/home";
export const CandidateSurveyLogoutRedirect = "/candidate/login";
export const UnauthorizedRedirect = "/employer/unauthorized";
export const CandidateLogoutRedirect = "/candidate/apply-job";
export const DefaultRoleForRAC: TAccountType = 'employer';
export const SessionExpiredTimer = 30;

const HiringMangerAndObserverBlockedPaths = [
    '/employer/job/new',
    '/employer/job/new/title',
    '/employer/job/:jobId/title',
    '/employer/job/:jobId/details',
    '/employer/job/:jobId/preview',
    '/employer/job/:jobId/bundles',
    '/employer/job/:jobId/payment',
    '/employer/job/:jobId/payment/success',
    '/employer/job/:jobId/payment/failed',
    '/employer/settings/account/company-information',
    '/employer/settings/account/access-levels'
];

const HiringMangerAndObserverBlockedFeatures: Partial<TFeaturesMap> = {
    'Create Job': {
        visibility: 'disabled'
    },
    'Company Details': {
        visibility: 'hidden'
    },
    'Manage Users': {
        visibility: 'hidden'
    },
    'Start Hiring': {
        visibility: 'disabled'
    },
    'Stop Hiring': {
        visibility: 'disabled'
    },
    'Edit Job': {
        visibility: 'disabled'
    },
    'Delete Job': {
        visibility: 'disabled'
    },
    'Re-post Job': {
        visibility: 'disabled'
    },
    'Template Jobs': {
        visibility: 'hidden'
    }
}

const _testFeatureMap: Partial<TFeaturesMap> = {
    // 'Template Jobs': { visibility: 'hidden' },
    // 'Create Template Job': { visibility: 'hidden' },
    // // 'Use Template Job': { visibility: 'hidden' }
}

export const TemplateJobPaths = [
    '/employer/jobs/template',
    '/employer/job/new/template-job/choose-type'
];

export const TemplateJobFeatureMap: Partial<TFeaturesMap> = {
    'Template Jobs': {
        visibility: 'hidden'
    },
    'Use Template Job': {
        visibility: 'hidden'
    },
    'Create Template Job': {
        visibility: 'hidden'
    }
}

/**
* Role based access control map used to identify the urls, features that are blocked for the current account's role.
*/
export const RoleAccessControlMap: TRoleAccessMap = {
    admin: { paths: [], featureMap: { ..._testFeatureMap } },
    candidate: { paths: [], featureMap: { ..._testFeatureMap } },
    employer: { paths: [], featureMap: { ..._testFeatureMap } },
    hiring_manager: {
        paths: [...HiringMangerAndObserverBlockedPaths, ...TemplateJobPaths],
        featureMap: { ...HiringMangerAndObserverBlockedFeatures, ...TemplateJobFeatureMap }
    },
    observer: {
        paths: [...HiringMangerAndObserverBlockedPaths, ...TemplateJobPaths],
        featureMap: { ...HiringMangerAndObserverBlockedFeatures, ...TemplateJobFeatureMap }
    },
    owner: { paths: [], featureMap: { ..._testFeatureMap } },
    recruiter: {
        paths: [],
        featureMap: {
            'Company Details': {
                visibility: 'hidden'
            },
            'Manage Users': {
                visibility: 'hidden'
            }
        }
    }
};

export const SocialProvidersMap: TSocialProvidersMap = {
    facebook: 'Facebook',
    google: 'Google',
    linkedin: 'LinkedIn',
    outlook: 'Outlook'
};

export const SocialProviders: ISocialProvider[] = [
    { displayName: 'Google', socialProviderKey: 'google' },
    // { displayName: 'Facebook', socialProviderKey: 'facebook' },
    { displayName: 'LinkedIn', socialProviderKey: 'linkedin' },
    { displayName: 'Outlook', socialProviderKey: 'outlook' },
];

export const EEOQutionaareData: EEOQutionaareItem[] = [
    {
      label: "What is your race?",
      type: "dropdown",
      options: ["White", "Black or African American", "Asian", "Other"],
    },
    {
      label: "What is your skin color or complexion?",
      type: "dropdown",
      options: ["Fair", "Light", "Medium", "Olive", "Tan", "Brown", "Dark"],
    },
    {
      label: "What is your gender?",
      type: "radio",
      options: ["Male", "Female", "Non-binary"],
    },
    {
      label: "What is your gender identity",
      type: "radio",
      options: ["Male", "Female", "Non-binary"],
    },
    {
      label: "What is your sexual orientation",
      type: "radio",
      options: ["Male", "Female", "Non-binary"],
    },
    {
      label: "What is your national origin or the country of your ancestry?",
      type: "text",
    },
    { label: "What is your age?", type: "text" },
    {
      label: "Are you a veteran of the U.S. Armed Forces?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      label: "Are you currently pregnant or have you recently been pregnant?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      label: "Do you have a physical or mental disability?",
      type: "radio",
      options: ["Yes", "No"],
    },
    {
      label:
        "Have you or any of your family members undergone genetic testing? If yes, what was the result of the test?",
      type: "radio",
      options: ["Yes", "No"],
    },
  ];