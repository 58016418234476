import { Box, Typography } from '@mui/material';
import { addDays, compareAsc, format, parseISO } from 'date-fns';
import { useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import {
    ResponsiveChartContainer,
    LinePlot,
    ChartsYAxis,
    ChartsXAxis,
    ChartsTooltip,
} from '@mui/x-charts';
import dayjs from 'dayjs';
import { PrimaryWordpressThemeColor } from 'shared/SharedStyles/styleConstants';

export const AnalyticsApplicantPerformance = () => {

    const { applicantsList: applicantsData } = useAppSelector((state) => state.employer.dashboard.dashboardApplicants);
    const [chartData, setChartData] = useState<{ x: Date[], y: number[] }>({ x: [], y: [] });
    
    const getApplicantAppliedDateRange = (firstApplicantDate: string) => {
        const startDate = parseISO(firstApplicantDate);
        const endDate = addDays(startDate, 29);
        return { startDate, endDate };
    };

    useEffect(() => {
        if (applicantsData && applicantsData.length > 0) {
            const sortedApplicants = [...applicantsData]?.sort((a, b) => compareAsc(parseISO(a.applicationCompletedAt), parseISO(b.applicationCompletedAt)));
            const firstApplicantDate = sortedApplicants[0].applicationCompletedAt;

            const { startDate, endDate } = getApplicantAppliedDateRange(firstApplicantDate);
            const dateCounts: { [key: string]: number } = {};

            for (let d = startDate; d <= endDate; d = addDays(d, 1)) {
                const dateStr = format(d, 'yyyy-MM-dd');
                dateCounts[dateStr] = 0;
            }
            applicantsData?.forEach(applicant => {
                const appliedDate = format(parseISO(applicant.applicationCompletedAt), 'yyyy-MM-dd');
                if (dateCounts[appliedDate] !== undefined) {
                    dateCounts[appliedDate] += 1;
                }
            });

            const x = Object.keys(dateCounts).map(date => new Date(date));
            const y = Object.values(dateCounts);
            setChartData({ x, y });
        }
    }, [applicantsData]);

    return (
            <Box width="100%">
                <Typography variant="subtitle1" align="center" gutterBottom>
                    Job Performance
                </Typography>
                <ResponsiveChartContainer
                    series={[{
                        type: 'line',
                        color: PrimaryWordpressThemeColor,
                        data: chartData.y,
                        valueFormatter: (value) => `${value} Candidates`,
                        label: "Number of Candidates Applied: "
                    }]}
                    xAxis={[{
                        data: chartData.x.map((date) => format(new Date(date), 'yyyy-MM-dd')),
                        scaleType: 'band',
                        id: 'x-axis-id',
                    }]}
                    height={200}>
                    <LinePlot loading/>
                    <ChartsYAxis labelFontSize={12} label="Number of Candidates" position="left" />
                    <ChartsXAxis valueFormatter={(date: string) => dayjs(date).format("MMM D")} />
                    <ChartsTooltip formatter={(params: string) => `${params.valueOf} # Candidates Applied`} />
                </ResponsiveChartContainer>
            </Box>   
    );
}