import { Box, Tab } from "@mui/material";
import { SettingsWrapper } from "Modules/Core/SettingsTs/Settings.styled";
import { SettingsPath, SettingsPathEnum } from "Modules/Core/SettingsTs/SettingsModel";
import { useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ShTabs } from "shared/SharedStyles/ShNavigation";
import { RoleAccessControlMap } from "../../AuthV2/AuthConstants";

export const AccountSettings = () => {

    const pathname = useLocation()?.pathname?.split('/').pop();
    const [curPage, setCurPage] = useState<SettingsPath>(pathname as SettingsPath);

    const { accountAccess } = useAppSelector((state) => state.auth.auth);

    // Set tab selection based on url path.
    useEffect(() => {
        if (pathname) {
            setCurPage(pathname as SettingsPath);
        }
    }, [pathname, accountAccess]);

    return (<>
        <SettingsWrapper>
            <Box paddingX={{ xs: 2, sm: 3, md: 4, lg: 4 }}>
                <ShTabs className="settings-tabs" aria-label="Settings Tabs" variant="scrollable" scrollButtons='auto'
                    allowScrollButtonsMobile value={curPage} onChange={(e, nV) => setCurPage(nV as SettingsPath)}>
                    <Tab label="Account Information" value={SettingsPathEnum.AccountInformation} component={Link}
                        to='/employer/settings/account/account-information' />
                    {RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                        ?.["Company Details"]?.visibility !== 'hidden' &&
                        <Tab label="Company Details" value={SettingsPathEnum.CompanyInformation}
                            component={Link} to='/employer/settings/account/company-information' disabled={
                                RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                                    ?.["Company Details"]?.visibility === 'disabled'} />}
                    {RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                        ?.['Manage Users']?.visibility !== 'hidden' &&
                        <Tab label="Manage Users" value={SettingsPathEnum.AccessLevels}
                            component={Link} to='/employer/settings/account/access-levels' disabled={
                                RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                                    ?.['Manage Users']?.visibility === 'disabled'} />}
                    <Tab label="Password & Security" value={SettingsPathEnum.Security}
                        component={Link} to='/employer/settings/account/security' />
                    {/* <Tab label="Branding" value={SettingsPathEnum.Branding}
                        component={Link} to='/employer/settings/account/branding' /> */}
                    {/* <Tab label="Integrations" value={SettingsPathEnum.Integrations}
                        component={Link} to='/employer/settings/account/integrations' /> */}
                </ShTabs>
            </Box>
            <Box className="settings-outlet" padding={{ xs: 2, sm: 3, md: 4, lg: 4 }}>
                <Outlet />
            </Box>
        </SettingsWrapper>
    </>);
};