import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { ShSettingsIcon } from "assets/Icons";
import { IsMdScreen, IsXsScreen } from "helpers/hooks";
import { Link as RouterLink } from 'react-router-dom';
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShIconPrimary, ShIconSecondary } from "shared/SharedStyles/styleConstants";
import CommonQuestionSidebar from "../CommonQuestionSidebar";

export const SetPermissions = () => {

  const isMdScreen = IsMdScreen();
  const paddingValue = IsXsScreen() ? 1 : 3;

  return (
    <>
      <Stack padding={paddingValue} direction={'row'}>
        <ShContainer margin={'auto'}>
          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Account Permissions</ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            To manage permissions for your team on our ATS platform, navigate to the
            <ShButton component={RouterLink} to="/employer/settings/account/account-information" color='inherit' variant="text"
              startIcon={<ShSettingsIcon primaryColor={ShIconPrimary} secondaryColor={ShIconSecondary} />}>
              Settings
            </ShButton>
            section. Here you can update roles and set permissions accordingly.
          </ResourceTypography>

          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Manage Users</ResourceTypography>
          <Box paddingTop={3} paddingBottom={3}>
            <Typography variant="body1" paragraph>
              The <strong>"Manage Users" </strong>section allows you to assign roles to your team members. Roles include Owner, Hiring Manager, Admin, and Recruiter. Each role has specific permissions:
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>All Jobs</TableCell>
                    <TableCell>Create</TableCell>
                    <TableCell>Assign</TableCell>
                    <TableCell>View</TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Row for Owner */}
                  <TableRow>
                    <TableCell>John Doe</TableCell>
                    <TableCell>john.doe@example.com</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <MoreVertIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                  {/* Row for Hiring Manager */}
                  <TableRow>
                    <TableCell>Jane Smith</TableCell>
                    <TableCell>jane.smith@example.com</TableCell>
                    <TableCell>Hiring Manager</TableCell>
                    <TableCell><ClearIcon color='error' /></TableCell>
                    <TableCell><ClearIcon color='error' /></TableCell>
                    <TableCell><ClearIcon color='error' /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <MoreVertIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                  {/* Row for Admin */}
                  <TableRow>
                    <TableCell>Michael Brown</TableCell>
                    <TableCell>michael.brown@example.com</TableCell>
                    <TableCell>Admin</TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <MoreVertIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                  {/* Row for Recruiter */}
                  <TableRow>
                    <TableCell>Sarah Johnson</TableCell>
                    <TableCell>sarah.johnson@example.com</TableCell>
                    <TableCell>Recruiter</TableCell>
                    <TableCell><ClearIcon color='error' /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <MoreVertIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Adam Sandler</TableCell>
                    <TableCell>sandler.adam@example.com</TableCell>
                    <TableCell>Observer</TableCell>
                    <TableCell><ClearIcon color='error' /></TableCell>
                    <TableCell><ClearIcon color='error' /></TableCell>
                    <TableCell><ClearIcon color='error' /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell><CheckIcon color="primary" /></TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <MoreVertIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>


        </ShContainer>
        {!isMdScreen && <CommonQuestionSidebar />}
      </Stack>
    </>
  );
};
