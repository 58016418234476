import ContactPageIcon from "@mui/icons-material/ContactPage";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import {
  Avatar,
  Box,
  Chip,
  Collapse,
  IconButton,
  LinearProgress,
  Stack,
  TableCell,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useAppSelector } from "helpers/hooks";
import { ApplicantsHeaders } from "Modules/Core/Applicants/ApplicantsConstants";
import { ApplicantComment } from "Modules/Core/Applicants/ApplicantsList/ApplicantComment";
import { ApplicantRating } from "Modules/Core/Applicants/ApplicantsList/ApplicantRating";
import { ApplicantSummary } from "Modules/Core/Applicants/ApplicantsList/ApplicantSummary";
import { StagesToggler } from "Modules/Core/Applicants/ApplicantsList/StagesToggler";
import {
  IApplicantRowModel,
  IApplicantsTable,
} from "Modules/Core/Applicants/ApplicantsModel";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import {
  CollapsibleTableRow,
  RowSelectCell,
  ShTable,
  ShTableContainer,
  ShTableRow,
  ThemeColorDivider,
} from "shared/SharedStyles/ShDataDisplay";
import { ShCheckbox } from "shared/SharedStyles/ShInputs";
import { stringToColor } from "shared/utils";
import { ResumeScoreGauge, getApplicantChipInfo } from "../ApplicantHelpers";

export const renderMatchChip = (matchType: string) => {
  const { chipColor, chipLabel, chipVariant, iconComponent } =
    getApplicantChipInfo(matchType);
  return (
    <Chip
      size="small"
      label={chipLabel}
      color={chipColor}
      variant={chipVariant}
      icon={iconComponent}
    />
  );
};

// Table Row component
const Row = ({
  applicant,
  selectedApplicants,
  selectApplicant,
  indx,
  applicantsSummary,
  getApplicantSummary,
  goToApplicantProfile,
  changeStage,
  isAtsPurchased,
  downloadHiringGuide,
}: IApplicantRowModel) => {
  return (
    <>
      <ShTableRow
        onClick={(e) => getApplicantSummary(applicant.candidateEmployerJobId)}
        cursor="pointer"
        id={`a_t_v_r_${applicant.candidateEmployerJobId}`}
      >
        <RowSelectCell onClick={(e) => e.stopPropagation()}>
          <ShCheckbox
            id={"chk_" + indx}
            checked={selectedApplicants?.includes(
              applicant.candidateEmployerJobId
            )}
            size="small"
            onChange={() => selectApplicant(applicant.candidateEmployerJobId)}
          />
        </RowSelectCell>
        <TableCell>
          <Stack
            direction="row"
            spacing={{ lg: 3, md: 2, sm: 1, xs: 0.5 }}
            alignItems="center"
          >
            <Avatar
              style={{
                backgroundColor: stringToColor(`${applicant.fullName}`),
              }}
              className="avatar"
              alt={applicant.fullName}
              src={`user-avatar-to-be-loaded-here`}
            />
            <Stack>
              <Typography variant="body2" fontWeight="bold">
                {applicant.fullName}
              </Typography>
              <Typography variant="caption">{applicant.email}</Typography>
            </Stack>
            <Tooltip
              title="View Applicant's details"
              onClick={(e) => e.stopPropagation()}
            >
              <Box
                component="span"
                onClick={(e) => {
                  e.stopPropagation();
                  goToApplicantProfile(applicant);
                }}
              >
                <ContactPageIcon
                  fontSize="small"
                  color="primary"
                  className="on-hover"
                />
              </Box>
            </Tooltip>
          </Stack>
        </TableCell>
        <TableCell>
          {applicant.applicationCompletedAt &&
            <Typography variant="body2">
              {moment(applicant.applicationCompletedAt).format("MMM DD, YYYY")}
            </Typography>}
        </TableCell>
        <TableCell>
          <ApplicantRating
            className="dsk-only-inline-flex"
            applicant={applicant}
            usedIn="list"
          />
        </TableCell>
        {/* Show this cell only for non ats jobs */}
        {!isAtsPurchased && (
          <TableCell>
            <Typography component={"div"} variant="body2">
              {renderMatchChip(applicant.matchType)}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <ResumeScoreGauge score={applicant.resumeScore || 0} />
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <StagesToggler
              applicant={applicant}
              usedIn="list"
              changeStage={changeStage}
            />
            <Stack>
              {applicant.hasReceivedRejectionEmail && (
                <ShTooltip
                  title="A rejection email was sent to the candidate"
                  placement="left-end"
                >
                  <IconButton
                    onClick={(e) => e.stopPropagation()}
                    style={{ cursor: "default" }}
                  >
                    <MarkEmailReadIcon color="primary" />
                  </IconButton>
                </ShTooltip>
              )}
              {/* this seems wrong discuss with Sri */}
              {/* { (applicant.jobStage === 'interview' || applicant.jobStage === 'second_interview') &&
                      <Tooltip title="Video Interview Scheduled" placement="top">
                          <IconButton>
                            <VideoChatIcon color='primary'/>
                        </IconButton>
                      </Tooltip>
                    }
                     */}
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <ApplicantComment applicantId={applicant.candidateEmployerJobId} />
        </TableCell>
      </ShTableRow>
      <CollapsibleTableRow>
        <TableCell colSpan={6}>
          {applicantsSummary[applicant.candidateEmployerJobId]
            ?.getSummaryStatus === "pending" && <LinearProgress />}
          {applicantsSummary[applicant.candidateEmployerJobId]
            ?.getSummaryStatus === "success" && (
              <Collapse in={applicant.isExpanded} timeout="auto" unmountOnExit>
                <ApplicantSummary
                  changeStage={changeStage}
                  applicant={applicant}
                  key={applicant.candidateEmployerJobId}
                  goToApplicantProfile={goToApplicantProfile}
                  isAtsPurchased={isAtsPurchased}
                  summary={applicantsSummary[applicant.candidateEmployerJobId]}
                  applicantId={applicant.candidateEmployerJobId}
                  downloadHiringGuide={downloadHiringGuide}
                />
                <ThemeColorDivider />
              </Collapse>
            )}
        </TableCell>
      </CollapsibleTableRow>
    </>
  );
};

export const ApplicantsTableView = ({
  applicantsList,
  applicantsSummary,
  isAtsPurchased,
  selectedApplicants,
  selectApplicant,
  sortList,
  sortParams,
  getApplicantSummary,
  goToApplicantProfile,
  changeStage,
  downloadHiringGuide,
}: IApplicantsTable) => {
  const location = useLocation();
  const { getApplicantsListStatus } = useAppSelector(
    (state) => state.employer.applicants.applicantsList
  );

  useEffect(() => {
    if (getApplicantsListStatus === "success") {
      const searchParams = new URLSearchParams(location.search);
      const applicantIdString = searchParams.get("applicantId");
      const applicantId = Number(applicantIdString) || undefined;
      const currRow = document.getElementById(`a_t_v_r_${applicantId}`);
      currRow?.setAttribute("tabindex", "0");
      currRow?.scrollIntoView({ behavior: "smooth", block: "center" });
      currRow?.focus();
    }
  }, [getApplicantsListStatus, location.search]);

  return (
    <>
      <ShTableContainer>
        <ShTable size="small">
          <TableHead>
            <ShTableRow cursor="pointer">
              <TableCell className="select-header"></TableCell>
              {/* Sortable constant table headers */}
              {ApplicantsHeaders.map((headCell) => (
                <TableCell
                  key={headCell.columName}
                  sortDirection={
                    sortParams.orderBy === headCell.columName
                      ? sortParams.order
                      : false
                  }
                >
                  {headCell.columName === "rating" ? ( // Apply tooltip and sorting label for rating col
                    <Typography
                      variant="body2"
                      display="flex"
                      alignItems="center"
                    >
                      <TableSortLabel
                        active={sortParams.orderBy === headCell.columName}
                        direction={
                          sortParams.orderBy === headCell.columName
                            ? sortParams.order
                            : "asc"
                        }
                        onClick={() => {
                          sortList(
                            headCell.columName,
                            sortParams.order === "asc" ? "desc" : "asc"
                          );
                        }}
                      >
                        {headCell.label}
                        &nbsp;
                        <Tooltip
                          title="Recruiters can rate their candidates based on interviews, qualifications, etc."
                          placement="top"
                        >
                          <InfoOutlinedIcon fontSize="small" color="disabled" />
                        </Tooltip>
                      </TableSortLabel>
                    </Typography>
                  ) : (
                    <TableSortLabel
                      active={sortParams.orderBy === headCell.columName}
                      direction={
                        sortParams.orderBy === headCell.columName
                          ? sortParams.order
                          : "asc"
                      }
                      onClick={() => {
                        sortList(
                          headCell.columName,
                          sortParams.order === "asc" ? "desc" : "asc"
                        );
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              {/* Show this column only for non ats jobs */}
              {!isAtsPurchased && (
                <TableCell>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="body2">Job Fit &nbsp;</Typography>
                    <Tooltip
                      title="This is your candidates match with the job's skills and their personality fit based on assessment."
                      placement="right"
                    >
                      <InfoOutlinedIcon fontSize="small" color="disabled" />
                    </Tooltip>
                  </Stack>
                </TableCell>
              )}
              <TableCell>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  display="flex"
                >
                  <Typography variant="body2">Resume Score</Typography>
                  <Chip
                    label="Beta"
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                  <ShTooltip
                    title="Your candidate's resume match with the job is in beta testing.
                                       Scores are accurate only for applications submitted after 10/28/2024. If scores are lower than expected, 
                                       consider updating your job description with more specific keywords."
                    placement="right"
                    showArrowIcon
                    arrowLink="/employer/help/optimize-job-posting"
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      color="disabled"
                      style={{ display: "flex", alignItems: "center" }}
                    />
                  </ShTooltip>
                </Stack>
              </TableCell>
              <TableCell>Stage</TableCell>
              <TableCell></TableCell>
            </ShTableRow>
          </TableHead>
          <TableBody>
            {applicantsList.map((aplcnt, index) => (
              // Row component holds both applicant and collapsible summary rows.
              <Row
                key={index}
                indx={index}
                applicant={aplcnt}
                goToApplicantProfile={goToApplicantProfile}
                applicantsSummary={applicantsSummary}
                selectedApplicants={selectedApplicants}
                getApplicantSummary={getApplicantSummary}
                isAtsPurchased={isAtsPurchased}
                selectApplicant={(applcntId: number) =>
                  selectApplicant(applcntId)
                }
                downloadHiringGuide={downloadHiringGuide}
                changeStage={changeStage}
              />
            ))}
          </TableBody>
        </ShTable>
      </ShTableContainer>
    </>
  );
};
