import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ShBorderRadius, ShOnHover } from 'shared/SharedStyles/styleConstants';

export const DashboardFeatureTile = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: ShBorderRadius,
  textDecoration: 'none',
  cursor: 'pointer',
  minWidth: 260,
  maxWidth: 360,
  flex: 1,
  ...(ShOnHover(true, theme)),
}));

export const jobBoxStyles = {
  padding: 2,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: 4,
  textDecoration: 'none',
  transition: 'transform 0.3s ease-out, box-shadow 0.3s ease-out',
  '&:hover': {
    background: '#DFE8F6',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
};

export const ShTimeline = styled(Timeline)(({ theme }) => ({
  padding: 0,
  '& .MuiTimelineItem-root': {
    '&:before': {
      flex: 0,
      padding: 0,
    },
  },
}));

export const ShTimelineItem = styled(TimelineItem)(({ theme }) => ({
  paddingLeft: 0,
  marginLeft: 0,
  '& .MuiTimelineContent-root': {
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    flexGrow: 1,
    margin: 0,
    marginRight: theme.spacing(2),
  },
  '& .MuiTimelineOppositeContent-root': {
    margin: 0,
    textAlign: 'left',
  },
}));

export const DASHBOARD_MENU_ITEM_HEIGHT = 48;
export const DASHBOARD_MENU_ITEM_PADDING_TOP = 8;
export const DashboardMenuProps = {
  PaperProps: {
    style: {
      maxHeight: DASHBOARD_MENU_ITEM_HEIGHT * 4.5 + DASHBOARD_MENU_ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
