import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts';

export const AnalyticsResumeScore = () => {
    const { applicantsList: applicantsData } = useAppSelector((state) => state.employer.dashboard.dashboardApplicants);
    const [barChartData, setBarChartData] = useState<{ id: string; value: number; color: string }[]>([]);

    useEffect(() => {
        if (applicantsData && applicantsData.length > 0) {
            const scoreBins: Record<string, number> = {};

            // Bin size
            const binSize = 10;
            
            applicantsData.forEach((applicant) => {
                const score = applicant.resumeScore;
                if (score !== undefined) {
                    // Calculate the bin range
                    const binStart = Math.floor(score / binSize) * binSize;
                    const binEnd = binStart + binSize - 1;
                    const binKey = `${binStart}-${binEnd}`;

                    // Increment the bin count
                    scoreBins[binKey] = (scoreBins[binKey] || 0) + 1;
                }
            });

            // Define a color palette
            const colorPalette = ['#4CAF50', '#2196F3', '#FFC107', '#FF5722', '#9C27B0', '#00BCD4', '#795548'];

            // Format data with color property and bin labels
            const formattedData = Object.entries(scoreBins).map(([id, value], index) => ({
                id, // Bin range
                value, // Count of applicants in this bin
                color: colorPalette[index % colorPalette.length], // Assign colors cyclically
            }));

            setBarChartData(formattedData);
        }
    }, [applicantsData]);

    return (
        <Box>
            <Typography textAlign="center" variant="body2" gutterBottom>
                Applicants by Resume Score (Binned)
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center">
                <BarChart
                    series={[
                        {
                            dataKey: 'value',
                            label: 'Count',
                            color:'#4CAF50'
                        },
                    ]}
                    dataset={barChartData}
                    height={250}
                    width={1000}
                    xAxis={[
                        { dataKey: 'id', label: 'Resume Score Range', scaleType: 'band' }, // Bin range
                    ]}
                    yAxis={[
                        { dataKey: 'value', label: 'Count', scaleType: 'linear' }, 
                    ]}
                />
            </Box>
        </Box>
    );
};
