import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IIncompleteApplicant,
  IIncompleteApplicantsState,
} from "Modules/Core/Applicants/ApplicantsModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { exportToCSV } from "helpers/fileHandlers";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg, DefaultPageSize } from "shared/constants";

const initialIncompleteApplicantsListState: IIncompleteApplicantsState = {
  getIncompleteApplicantsListStatus: "idle",
  incompleteApplicantsList: [],
  paginationParams: {
    count: 0,
    requestedPageNumber: 1,
    requestedPageSize: DefaultPageSize,
    totalPages: 0,
  },
};

export const getIncompleteApplicantsList = createAsyncThunk<
  IBaseResponse<IIncompleteApplicant[]>,
  { jobId: number; pageNo: number | undefined; pageSize: number | undefined },
  { rejectValue: IBaseResponse }
>(
  "getIncompleteApplicantsList",
  async ({ jobId, pageNo, pageSize }, { rejectWithValue }) => {
    return await httpAdapterInstance
      .get(
        `${EmployerApiEndpoints.APPLICANT_INCOMPLETE}/list?jobId=${jobId}${
          pageNo && pageSize ? `&pageNo=${pageNo}&pageSize=${pageSize}` : ""
        } `
      )
      .then(
        (response: AxiosResponse<IBaseResponse<IIncompleteApplicant[]>>) =>
          response?.data
      )
      .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
  }
);

export const exportIncompleteApplicantsListAsCSV = createAsyncThunk<
  IBaseResponse,
  { jobId: number },
  { rejectValue: IBaseResponse }
>(
  "exportIncompleteApplicantsListAsCSV",
  async ({ jobId }, { rejectWithValue }) => {
    return await httpAdapterInstance
      .get(`${EmployerApiEndpoints.APPLICANT_INCOMPLETE}/export?jobId=${jobId}`)
      .then((response: AxiosResponse<IBaseResponse>) => response?.data)
      .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
  }
);

const incompleteApplicantsSlice = createSlice({
  name: "incompleteApplicants",
  initialState: initialIncompleteApplicantsListState,
  reducers: {
    resetIncompleteApplicants: () => initialIncompleteApplicantsListState,
    resetExportApplicantsAsCSV: (state) => {
      state.downloadAsCSVStatus = "idle";
      state.paginationParams = {
        count: 0,
        requestedPageNumber: 1,
        requestedPageSize: DefaultPageSize,
        totalPages: 0,
      };
    },
  },
  extraReducers: (builder) => {
    // On Store PURGE reset the state
    builder.addCase(PURGE, () => {
      return initialIncompleteApplicantsListState;
    });
    // get incomplete applicants list.
    builder.addCase(getIncompleteApplicantsList.pending, (state) => {
      state.getIncompleteApplicantsListStatus = "pending";
    });
    builder.addCase(getIncompleteApplicantsList.fulfilled, (state, action) => {
      state.getIncompleteApplicantsListStatus = "success";
      state.getIncompleteApplicantsListResponse = action?.payload.message;
      state.incompleteApplicantsList = action.payload?.data;
      state.paginationParams = action?.payload.stats;
      // Add total records counts in the response.
      state.paginationParams.count = action.payload.data?.length;
    });
    builder.addCase(getIncompleteApplicantsList.rejected, (state, action) => {
      state.getIncompleteApplicantsListStatus = "failed";
      state.getIncompleteApplicantsListResponse =
        action?.payload?.message ?? DefaultAPIErrorMsg;
      state.paginationParams = {
        count: 0,
        requestedPageNumber: 1,
        requestedPageSize: DefaultPageSize,
        totalPages: 0,
      };
    });
    // download as csv
    builder.addCase(exportIncompleteApplicantsListAsCSV.pending, (state) => {
      state.downloadAsCSVStatus = "pending";
    });
    builder.addCase(
      exportIncompleteApplicantsListAsCSV.fulfilled,
      (state, action) => {
        state.downloadAsCSVStatus = "success";
        // Download file handler.
        exportToCSV(action?.payload, "Incomplete Applicants");
      }
    );
    builder.addCase(
      exportIncompleteApplicantsListAsCSV.rejected,
      (state, action) => {
        state.downloadAsCSVStatus = "failed";
      }
    );
  },
});

export const { resetExportApplicantsAsCSV, resetIncompleteApplicants } =
  incompleteApplicantsSlice.actions;
export default incompleteApplicantsSlice;
