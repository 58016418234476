import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
    Accordion, AccordionDetails, AccordionSummary, Alert, Divider, LinearProgress, Stack, Tooltip, Typography
} from '@mui/material';
import { JobActions } from 'Modules/Core/JobsTs/JobsListTs/JobActions';
import { JobSummary } from 'Modules/Core/JobsTs/JobsListTs/JobSummary';
import { IJobsComponentBase } from 'Modules/Core/JobsTs/JobsModel';
import moment from "moment";
import { MouseEvent, useEffect, useState } from 'react';
import { ThemeColorDivider } from 'shared/SharedStyles/ShDataDisplay';
import { ShCheckbox } from 'shared/SharedStyles/ShInputs';
import { ListPaperContainer } from 'shared/SharedStyles/ShSurfaces';
import { getUrlSearchParams } from 'shared/utils';
import { ApplicantsCount } from './ApplicantsCount';

export const JobsListView = ({ getJobSummary, jobsList, jobsSummary, selectJob, selectedJobs, jobsType }: IJobsComponentBase) => {

    /*
    Using map with Job id as key and element ref as value since the menu's ref is present in multiple locations opening one
    menu toggles multiple menus to open. With the help of this map toggling menu for appropriate element is possible.
    */
    const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState<{ [key: number]: HTMLElement | null }>({ 0: null });

    useEffect(() => {
        const currRow = document.getElementById(`j_l_v_r_${Number(getUrlSearchParams('jobId'))}`);
        currRow?.setAttribute("tabindex", "0");
        currRow?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        currRow?.focus();
    }, []);

    const openMoreMenu = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, indx: number) => {
        e.stopPropagation();
        setMoreOptionsAnchorEl({ ...moreOptionsAnchorEl, [indx]: e.currentTarget });
    };

    return (<>
        <ListPaperContainer variant='outlined'>
            {jobsList.map((job, index) => (
                <Accordion disableGutters variant='outlined' key={index}
                    expanded={job.isExpanded === undefined ? false : job.isExpanded} onChange={() => getJobSummary(job.id)}
                    id={`j_l_v_r_${job.id}`}>
                    <AccordionSummary aria-controls={`panel_${index}_control`} id={`panel_${index}_header`}
                        className={moreOptionsAnchorEl[job.id] ? 'is-actions-open' : ''}>
                        <ShCheckbox id={job.id?.toString()} checked={selectedJobs?.includes(job.id)}
                            size='small' onClick={(e) => e.stopPropagation()} onChange={() => selectJob(job.id)} />
                        <ApplicantsCount job={job} usedIn='List' jobsType={jobsType} />
                        <Stack ml={2}>
                            <Stack flexDirection='row' mb={0.5} columnGap={{ xs: 0.5, sm: 1, md: 2 }} alignItems='center'>
                                <Typography className='name-blk' variant="body2" fontWeight='bold' flexWrap='wrap'>
                                    {job.name}&nbsp;
                                    {job.starts_at && <Typography variant="caption">
                                        ({moment(job.starts_at).format("MMM DD, YYYY")} - {moment(job.ends).format("MMM DD, YYYY")})
                                    </Typography>}
                                    {/* Hide open job details icons for draft jobs */}
                                    {jobsType !== 'draft' &&
                                        <Tooltip title="View Job's details in new tab" onClick={e => e.stopPropagation()}>
                                            <OpenInNewIcon fontSize='small' color='primary' className='on-hover' />
                                        </Tooltip>}
                                </Typography>
                            </Stack>
                            <Stack display={{ xs: 'none', sm: 'flex', md: 'flex' }} flexDirection='row' columnGap={1}>
                                {/* Display labels if only data exists */}
                                {job.city && job.state && <>
                                    <Typography variant="caption" className='info'>Location : {`${job.city}, ${job.state}`}</Typography>
                                    <Divider flexItem orientation='vertical' /></>}
                                {/* {job.owner && <>
                                    <Typography variant="caption" className='info'>Owner : {job.owner}</Typography>
                                    <Divider flexItem orientation='vertical' />
                                </>} */}
                                {job.hiring_manager && <Typography variant="caption" className='info'>Hiring Manager : {job.hiring_manager}</Typography>}
                                <Divider flexItem orientation='vertical' />
                                {job.id && <Typography variant="caption" className='info'>Job ID : {job.id}</Typography>}

                            </Stack>
                        </Stack>
                        <JobActions key={job.id} anchorEl={moreOptionsAnchorEl[job.id]} job={job} jobsType={jobsType}
                            openMoreMenu={openMoreMenu} setMoreOptionsAnchorEl={setMoreOptionsAnchorEl} actionMenuType='popup' />
                    </AccordionSummary>
                    {jobsType !== 'draft' &&
                        <AccordionDetails>
                            {/* Show loading and error feedback. */}
                            {jobsSummary[job.id]?.getSummaryStatus === 'pending' && <LinearProgress />}
                            {jobsSummary[job.id]?.getSummaryStatus === 'failed' &&
                                <Alert severity="error">{jobsSummary[job.id]?.getSummaryResponse ?? 'Something went wrong!'}</Alert>}
                            {jobsSummary[job.id]?.getSummaryStatus === 'success' && <>
                                {/* Mobile view section with Rating and Stage Toggle component*/}
                                <ThemeColorDivider />
                                <Stack display={{ xs: 'flex', sm: 'none', md: 'none' }} padding={1}
                                    justifyContent='space-between'>
                                    {/* Display labels if only data exists */}
                                    {job.city && job.state && <>
                                        <Typography variant="caption" className='info'>Location : {`${job.city}, ${job.state}`}</Typography>
                                        <Divider flexItem orientation='vertical' /></>}
                                    {/* {job.owner && <>
                                        <Typography variant="caption" className='info'>Owner : {job.owner}</Typography>
                                        <Divider flexItem orientation='vertical' />
                                    </>} */}
                                    {job.hiring_manager && <Typography variant="caption" className='info'>Hiring Manager : {job.hiring_manager}</Typography>}
                                </Stack>
                                <ThemeColorDivider />
                                <JobSummary key={index} summary={jobsSummary[job.id]} jobsType={jobsType} job={job} />
                            </>}
                        </AccordionDetails>}
                </Accordion>
            ))}
        </ListPaperContainer>
    </>);
};
