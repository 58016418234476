import App from "App";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import store, { persister } from "store/index";
import { msalConfig } from "authConfig";

const msalInstance = new PublicClientApplication(msalConfig);
const rootElement = document.getElementById("root");

if (rootElement) {

  const root = ReactDOM.createRoot(rootElement);

  root.render(<Provider store={store}>
    <PersistGate persistor={persister}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </PersistGate>
  </Provider>);
}