import CheckIcon from "@mui/icons-material/Check";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { StyledStack } from "../Admin/EmployerProfile/EmployerDetails.styled";

const sampleOptions = ["Option 1", "Option 2", "Option 3", "Option 4"];


export const AdditionalQuestions = () => {

  const [showForm, setShowForm] = useState<boolean>(false);
  const [showPhoneNumberForm, setShowPhoneNumberForm] = useState<boolean>(false);
  const [showCountryrForm, setShowCountryForm] = useState<boolean>(false);
  const [showDesiredSalaryForm, setShowDesiredSalaryForm] = useState<boolean>(false);
  const [showCoverLetterForm, setShowCoverletterForm] = useState<boolean>(false);
  const [showBackgroundCheckForm, setShowBackgroundCheckForm] = useState<boolean>(false);
  const [showCertificationsForm, setShowCertificationsForm] = useState<boolean>(false);
  const [showDriversLicenseForm, setShowDriversLicenseForm] = useState<boolean>(false);
  const [showDrugTestForm, setShowDrugTestForm] = useState<boolean>(false);
  const [showAuthorizedWorkForm, setShowAuthorizedWorkForm] = useState<boolean>(false);



  const [responses, setResponses] = useState<{ [key: string]: string | null }>({
    label: null,
  });
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setShowForm(event.target.checked);
  };

  const handleChange = (label: string, value: string) => {
    setResponses((prev) => ({ ...prev, [label]: value }));
  };

  return (
    <StyledStack spacing={2}>
      <Typography variant="h6" gutterBottom>
        Additional Questions
      </Typography>

      {/*  */}

      <Stack>
        <Typography variant="subtitle2">
          Here you can manage additional questions for the employer.
        </Typography>
      </Stack>

      {/* Section for address input */}
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">
              Provide your address
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="address_provided"
              inputProps={{ "aria-label": "Please provide address" }}
              checkedIcon={<CheckIcon />}
              onChange={handleCheckboxChange}
            />
          </Grid>
        </Grid>

        {/* Address input fields */}
        {showForm &&
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "30%",
            }}
          >
            <TextField
              label="Address 1"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
            <TextField
              label="Address 2"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
            <Autocomplete
              options={sampleOptions}
              noOptionsText="No matches found"
              disablePortal
              size="small"
              getOptionLabel={(option) => option || ""}
              value={responses["label"] || null}
              onChange={(e, newValue) => {
                handleChange("label", newValue || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!responses["label"]}
                  helperText={
                    !responses["label"] ? "This field is required." : null
                  }
                  required
                  variant="outlined"
                  placeholder="Select a city"
                //   InputProps={{
                //     ...params.InputProps,
                //     endAdornment: (
                //       <>
                //         <CircularProgress color="inherit" size={20} />
                //         {params.InputProps.endAdornment}
                //       </>
                //     ),
                //   }}
                />
              )}

            />
            <Autocomplete
              options={sampleOptions}
              noOptionsText="No matches found"
              disablePortal
              size="small"
              getOptionLabel={(option) => option || ""}
              value={responses["label"] || null}
              onChange={(e, newValue) => {
                handleChange("label", newValue || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!responses["label"]}
                  helperText={
                    !responses["label"] ? "This field is required." : null
                  }
                  required
                  variant="outlined"
                  placeholder="Select a State"
                //   InputProps={{
                //     ...params.InputProps,
                //     endAdornment: (
                //       <>
                //         <CircularProgress color="inherit" size={20} />
                //         {params.InputProps.endAdornment}
                //       </>
                //     ),
                //   }}
                />
              )}

            />
            <TextField
              label="Zipcode"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
            <Button variant="contained">Continue</Button>
          </Box>
        }
      </Stack>

      {/* Phone number form */}
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">
              Provide your Phone
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="phone_provided"
              inputProps={{ "aria-label": "Please provide phone number" }}
              checkedIcon={<CheckIcon />}
              onChange={(e) => setShowPhoneNumberForm(e.target.checked)}
            />
          </Grid>
        </Grid>

        {showPhoneNumberForm &&
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "30%",
            }}
          >

            <TextField
              label="Please Provide Phone Number"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
          </Box>
        }
      </Stack>

      {/* country field */}
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">
              Provide your Country
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="country_provided"
              inputProps={{ "aria-label": "Please provide Country" }}
              checkedIcon={<CheckIcon />}
              onChange={(e) => setShowCountryForm(e.target.checked)}
            />
          </Grid>
        </Grid>

        {showCountryrForm &&
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "30%",
            }}
          >

            <Autocomplete
              options={sampleOptions}
              noOptionsText="No matches found"
              disablePortal
              size="small"
              getOptionLabel={(option) => option || ""}
              value={responses["label"] || null}
              onChange={(e, newValue) => {
                handleChange("label", newValue || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!responses["label"]}
                  helperText={
                    !responses["label"] ? "This field is required." : null
                  }
                  required
                  variant="outlined"
                  placeholder="Select a Country"
                //   InputProps={{
                //     ...params.InputProps,
                //     endAdornment: (
                //       <>
                //         <CircularProgress color="inherit" size={20} />
                //         {params.InputProps.endAdornment}
                //       </>
                //     ),
                //   }}
                />
              )}

            />
          </Box>
        }
      </Stack>

      {/* desired salary */}
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">
              Provide Desired Salary
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="desired_Salary"
              inputProps={{ "aria-label": "Please provide Desired Salary" }}
              checkedIcon={<CheckIcon />}
              onChange={(e) => setShowDesiredSalaryForm(e.target.checked)}
            />
          </Grid>
        </Grid>

        {showDesiredSalaryForm &&

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "30%",
            }}
          >
            <TextField
              label="Please provide Desired Salary"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
          </Box>
        }
      </Stack>

      {/* Cover letter */}
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">
              Provide Cover Letter
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="cover_letter"
              inputProps={{ "aria-label": "Please provide Cover Letter" }}
              checkedIcon={<CheckIcon />}
              onChange={(e) => setShowCoverletterForm(e.target.checked)}
            />
          </Grid>
        </Grid>

        {showCoverLetterForm &&
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "30%",
            }}
          >
            <TextField
              label="Attach cover letter"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
          </Box>
        }
      </Stack>


      {/* Background Check */}
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">
              Provide Background Check
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="cover_letter"
              inputProps={{ "aria-label": "Please provide Background Check" }}
              checkedIcon={<CheckIcon />}
              onChange={(e) => setShowBackgroundCheckForm(e.target.checked)}
            />
          </Grid>
        </Grid>

        {showBackgroundCheckForm &&

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "30%",
            }}
          >
            <TextField
              label="Please Provide Background Check"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
          </Box>
        }
      </Stack>

      {/* Certifications */}
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">
              Provide Certifications
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="cover_letter"
              inputProps={{ "aria-label": "Please provide Certifications" }}
              checkedIcon={<CheckIcon />}
              onChange={(e) => setShowCertificationsForm(e.target.checked)}
            />
          </Grid>
        </Grid>

        {showCertificationsForm &&
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "30%",
            }}
          >
            <TextField
              label="Please Provide Certifications"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
          </Box>
        }
      </Stack>

      {/* Driver's License */}
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">
              Provide Driver's License
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="cover_letter"
              inputProps={{ "aria-label": "Please provide Driver's License" }}
              checkedIcon={<CheckIcon />}
              onChange={(e) => setShowDriversLicenseForm(e.target.checked)}
            />
          </Grid>
        </Grid>

        {showDriversLicenseForm &&

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "30%",
            }}
          >
            <TextField
              label="Please provide Driver's License Number"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
          </Box>
        }
      </Stack>

      {/* Drug Test */}
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">
              Provide Drug Test
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="cover_letter"
              inputProps={{ "aria-label": "Please provide Drug Test" }}
              checkedIcon={<CheckIcon />}
              onChange={(e) => setShowDrugTestForm(e.target.checked)}
            />
          </Grid>
        </Grid>

        {showDrugTestForm &&

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "30%",
            }}
          >
            <TextField
              label="Provide Drug Test"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
          </Box>
        }
      </Stack>

      {/* Authorized Work Form */}
      <Stack spacing={2}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="subtitle2">
              Are you legally authorized to work in the United States?
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              name="Authorized_Work"
              inputProps={{ "aria-label": "Please provide Are you legally authorized to work" }}
              checkedIcon={<CheckIcon />}
              onChange={(e) => setShowAuthorizedWorkForm(e.target.checked)}
            />
          </Grid>
        </Grid>

        {showAuthorizedWorkForm &&

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "30%",
            }}
          >
            <TextField
              label="Are you legally authorized to work in the United States?"
              variant="outlined"
              fullWidth
              size="small"
              required

            />
          </Box>
        }
      </Stack>

    </StyledStack>
  );
};
