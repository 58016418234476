import DraftsIcon from '@mui/icons-material/Drafts'
import { Box, darken, Divider, lighten, LinearProgress, Stack, Typography } from "@mui/material"
import { RoleAccessControlMap } from "Modules/Core/AuthV2/AuthConstants"
import { IJob, IJobsApiParams } from "Modules/Core/JobsTs/JobsModel"
import { useAppDispatch, useAppSelector } from "helpers/hooks"
import { useCallback, useEffect } from "react"
import { Link as RouterLink } from 'react-router-dom'
import { ShAvatar, ShChip } from "shared/SharedStyles/ShDataDisplay"
import { ShButton } from "shared/SharedStyles/ShInputs"
import { ShMuiLink } from "shared/SharedStyles/ShNavigation"
import { ShPaper } from "shared/SharedStyles/ShSurfaces"
import { ShGreen } from "shared/SharedStyles/styleConstants"
import { getInitialsFromName, stringToColor, toTitleCase } from "shared/utils"
import { fetchDraftDashboardJobsList } from "store/slices/employer/employer/dashboard/dashboard-draft-jobs-slice"
import { DashboardListSize } from "../DashboardConstants"

export const DashboardDraftJobWidget = () => {

    const { jobsList: draftJobsList, getJobsListStatus } = useAppSelector(state => state.employer.dashboard.dashboardDraftJobs);
    const { accountAccess } = useAppSelector((state) => state.auth.auth);

    const dispatch = useAppDispatch();
    const getJobs = useCallback(() => {
        const params: IJobsApiParams = {
            jobType: 'draft',
            pageNumber: 1,
            pageSize: DashboardListSize,
            sortDirection: 'desc',
            sortColumn: 'created_at',
            search: '',
        };
        // FOR FETCHING DRAFT JOBS
        dispatch(fetchDraftDashboardJobsList(params));
    }, [dispatch]);

    // Get jobs list on page load.
    useEffect(() => {
        getJobs();
    }, [dispatch, getJobs]);

    const DRAFT_JOBS_FEATURE_KEY = 'Draft Jobs';

    return (
        <>
            {
                (RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap?.[DRAFT_JOBS_FEATURE_KEY]?.visibility !== 'disabled' &&
                    <ShPaper height='100%' flex={1} variant='outlined'>
                        <Stack maxHeight='100%' height='100%' overflow='auto' minWidth={300}>
                            <Stack justifyContent="space-between" alignItems="center" direction='row' marginBottom={2}>
                                <Typography variant="body1" fontWeight="bold" display='flex' columnGap={1} alignItems='center'>
                                    <DraftsIcon color='primary' />
                                    Finish Draft Jobs</Typography>
                                {draftJobsList && draftJobsList.length !== 0 && (
                                    <ShButton variant="outlined" color="primary" component={RouterLink}
                                        to="/employer/jobs">See all jobs</ShButton>
                                )}
                            </Stack>
                            {getJobsListStatus === "pending" ?
                                <>
                                    <LinearProgress />
                                </> :
                                <>
                                    <Stack justifyContent="space-between" alignItems="center" direction='row' paddingX={2} marginBottom={1}>
                                        <Typography variant="body2">Job Title</Typography>
                                        <Typography variant="body2">Hiring Manager</Typography>
                                    </Stack>
                                    <Divider />
                                    <Stack spacing={1} width='100%' overflow='auto' paddingRight={1} paddingTop={1}>
                                        {draftJobsList && draftJobsList.length === 0 ? (
                                            <Typography variant="body2" color="textSecondary" fontWeight="bold" textAlign='center'>
                                                No Draft Jobs Yet.
                                            </Typography>) :
                                            (draftJobsList
                                                ?.filter((job: IJob) => job.name !== null && job.hiring_manager?.length > 0)
                                                .map((job: IJob) => (
                                                    <Box key={job.id}>
                                                        <ShMuiLink noUnderline component={RouterLink} to={`/employer/job/${job.id}/title`} transElevateOnHover
                                                            display='flex' alignItems='center' justifyContent='space-between'
                                                            columnGap={2} rowGap={1} noBlueHighlight padding={1}>
                                                            <Stack direction='row' alignItems='center' columnGap={1}>
                                                                <ShAvatar height={40} width={40} backgroundColor={stringToColor(job.hiring_manager)}>
                                                                    <Typography variant='body1'>
                                                                        {getInitialsFromName(job.hiring_manager, true, true)}
                                                                    </Typography>
                                                                </ShAvatar>
                                                                <Typography variant="body2" color="textPrimary" fontWeight="bold">
                                                                    {job.name}
                                                                </Typography>
                                                            </Stack>
                                                            {job.hiring_manager &&
                                                                <ShChip textColor={darken(ShGreen, .6)} bgColor={lighten(ShGreen, .6)}
                                                                    variant='filled' color='success' label={toTitleCase(job.hiring_manager)} size='small' />}

                                                        </ShMuiLink>
                                                    </Box>
                                                )))}
                                    </Stack>
                                </>}
                        </Stack>
                    </ShPaper>
                )}
        </>
    );
};
