import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton } from "@mui/material";
import { Banner } from "Modules/Core/Banner/Banner";
import { InterviewDialog } from "Modules/Core/Interviews/InterviewDialog";
import { LeftDrawerStyled, MainContainer, ParentContainerStyled, SideNavContainer, Wrapper } from "Modules/Core/Layouts/AppLayout/AppLayout.styled";
import { SideNav } from 'Modules/Core/Layouts/AppLayout/SideNav';
import { Notification } from 'Modules/Core/Notification';
import { EmployerBreadcrumbs } from "Modules/Core/ShBreadcrumbs/EmployerBreadcrumbs";
import { WelcomeDialog } from "Modules/Core/WelcomeDialog";
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from "react";
import { Outlet } from 'react-router-dom';
import { LeftPanelContainer } from "shared/SharedStyles/ShLayouts";
import { getEmployerDetails } from "store/slices/employer/employer/employer-details-slice";
import { EmployerTopNav } from "./EmployerTopNav";

const AppLayout = () => {
  const dispatch = useAppDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMiniDrawer, setIsMiniDrawer] = useState(true);
  const { employerId } = useAppSelector((state) => state.auth.auth);
  const { employerDetails } = useAppSelector(state => state.employer.employerDetails);

  const toggleDrawerHandler = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  // Load employer info once logged in.
  useEffect(() => {
    if (employerId && !employerDetails) {
      dispatch(getEmployerDetails({ empId: employerId }));
    }
  }, [employerId, dispatch, employerDetails]);

  return (<>
    <WelcomeDialog />
    <ParentContainerStyled>
      {/* Snackbar type Notifications component */}
      <Notification />
      <EmployerTopNav setIsDrawerOpen={setIsDrawerOpen} />
      {/* Wrapper holds side nav and main outlet for components */}
      <Wrapper>
        <LeftDrawerStyled anchor="left" open={isDrawerOpen} onClose={toggleDrawerHandler}>
          {/* Side nav wrapper switches the different side nav components based on the route */}
          <SideNav toggleDrawer={toggleDrawerHandler} />
          {/* Toggle drawer prop is only sent in mobile view to close the drawer overlay while navigating to components. */}
        </LeftDrawerStyled>
        <SideNavContainer className={isMiniDrawer ? 'is-mini-drawer' : ''}>
          <LeftPanelContainer>
            <SideNav isMiniDrawer={isMiniDrawer} />
          </LeftPanelContainer>
        </SideNavContainer>
        <MainContainer>
          {/* Material Breadcrumbs component wraps around links with 'Home' as constant and dynamic path names.
          */}
          {employerDetails?.free_trail_signup && !employerDetails.free_trail_job_id && <Banner />}
          <Box className='breadcrumb-control'>
            <IconButton className='side-bar-tgl' size="medium" edge="start" onClick={() => setIsMiniDrawer(!isMiniDrawer)}>
              <MenuIcon />
            </IconButton>
            <EmployerBreadcrumbs />
          </Box>
          <Box component='div' className="main-outlet-container">
            <Outlet />
            {/* Interviews dialog */}
            <InterviewDialog />
          </Box>
        </MainContainer>
      </Wrapper >
    </ParentContainerStyled>
  </>
  );
};

export default AppLayout;