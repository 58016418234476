import { Box, Link, Stack, Typography } from '@mui/material';
import { ChartsTooltip, ChartsXAxis, ChartsYAxis, LinePlot, ResponsiveChartContainer } from "@mui/x-charts";
import { InviteApplicants } from 'Modules/Core/Applicants/InviteApplicants';
import { JobSummaryStack } from 'Modules/Core/JobsTs/JobsListTs/Jobs.styled';
import { IJob, IJobSummary } from 'Modules/Core/JobsTs/JobsModel';
import { IncompleteIcon } from 'assets/Icons';
import { addDays, compareAsc, format, parseISO } from 'date-fns';
import dayjs from "dayjs";
import { IsMdScreen, IsXsScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ShTooltip } from 'shared/SharedComponents/ShTooltip';
import { ISummaryComponentBase } from 'shared/SharedModels';
import { ShGreen } from 'shared/SharedStyles/styleConstants';
import { setBreadcrumbLabelRegistry, setBreadcrumbNavFrom } from 'store/slices/app/breadcrumb-slice';
import { JobType } from 'store/slices/employer/jobs/jobs-slice-model';
import { SummaryPanelMatchTypes } from '../JobsConstants';
import { JobActions } from './JobActions';

export const JobSummary = ({ summary, jobsType, job }: (ISummaryComponentBase<IJobSummary> & { jobsType: JobType, job: IJob })) => {

    const dispatch = useAppDispatch();
    const isXsScreen = IsXsScreen();
    const isMdScreen = IsMdScreen();
    const [isInviteApplicantsOpen, setIsInviteApplicantsOpen] = useState<boolean>(false);
    const [chartData, setChartData] = useState<{ x: Date[], y: number[] }>({ x: [], y: [] });
    const { paginationParams } = useAppSelector(state => state.employer.employerJobs.jobsList);

    const gotoApplicants = () => {
        dispatch(setBreadcrumbNavFrom({ breadcrumbNav: `${jobsType}_jobs` }));
        dispatch(setBreadcrumbLabelRegistry({
            labelReg: {
                [job.id]: `${job.name}`,
                [`jobs_${job.id}_page`]: `${paginationParams.requestedPageNumber}`,
                [`jobs_${job.id}_isExpand`]: 'true',
            }
        }));
    };

    const gotoIncompleteApplicants = () => {
        dispatch(setBreadcrumbNavFrom({ breadcrumbNav: `${jobsType}_jobs` }));
        dispatch(setBreadcrumbLabelRegistry({
            labelReg: {
                [job.id]: `${job.name}`,
                [`jobs_${job.id}_page`]: `${paginationParams.requestedPageNumber}`,
                [`jobs_${job.id}_isExpand`]: 'true',
            }
        }));
    };

    const getApplicantAppliedDateRange = (firstApplicantDate: string) => {
        const startDate = parseISO(firstApplicantDate);
        const endDate = addDays(startDate, 29);
        return { startDate, endDate };
    };

    useEffect(() => {
        if (summary?.applicantList && summary?.applicantList.length > 0) {
            const sortedApplicants = [...summary.applicantList]?.sort((a, b) => compareAsc(parseISO(a.applicationCompletedAt), parseISO(b.applicationCompletedAt)));
            const firstApplicantDate = sortedApplicants[0].applicationCompletedAt;

            const { startDate, endDate } = getApplicantAppliedDateRange(firstApplicantDate);
            const dateCounts: { [key: string]: number } = {};

            for (let d = startDate; d <= endDate; d = addDays(d, 1)) {
                const dateStr = format(d, 'yyyy-MM-dd');
                dateCounts[dateStr] = 0;
            }
            summary?.applicantList.forEach(applicant => {
                const appliedDate = format(parseISO(applicant.applicationCompletedAt), 'yyyy-MM-dd');
                if (dateCounts[appliedDate] !== undefined) {
                    dateCounts[appliedDate] += 1;
                }
            });

            const x = Object.keys(dateCounts).map(date => new Date(date));
            const y = Object.values(dateCounts);
            setChartData({ x, y });
        }
    }, [summary]);

    return (<>
        <InviteApplicants isInviteApplicantsOpen={isInviteApplicantsOpen} job={job}
            setIsInviteApplicantsOpen={setIsInviteApplicantsOpen} />
        {/* {summary.matchTypeCounts?.find(mtc => mtc.count !== 0) &&
           } */}
        <JobSummaryStack>
            {jobsType !== 'draft' && <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                    <Stack paddingX={{ xs: 0, sm: 2, md: 3, lg: 3, xl: 3 }} minWidth={180}>
                        <Box className='job-detail'>
                            <Typography variant='subtitle2' className='label' whiteSpace='nowrap'>Total Applicants</Typography>
                            <Typography className='value' fontWeight={600}>{summary.totalApplicantsCount}</Typography>
                        </Box>
                        {!summary.isAtsJob && <>
                            {SummaryPanelMatchTypes.map(matchType => (
                                <Box className='job-detail' key={matchType.matchLabel}>
                                    {matchType.matchTypeIcon}
                                    <Typography variant='subtitle2' className='label'>{matchType.matchLabel}</Typography>
                                    {
                                        summary.matchTypeCounts?.find(mtc => {
                                            if (typeof matchType.matchType === 'string') {
                                                return mtc.matchType === matchType.matchType;
                                            } else {
                                                return matchType.matchType.includes(mtc.matchType)
                                            }
                                        })?.count ? <>
                                            <ShTooltip title={`View ${matchType.matchLabel} applicants list`} placement='right-start'>
                                                <Link component={RouterLink} onClick={gotoApplicants}
                                                    to={`/employer/applicants/${job.id}?matchType=${matchType.matchTypeForFilter}`}
                                                >
                                                    <Typography>
                                                        {summary.matchTypeCounts?.find(mtc => {
                                                            if (typeof matchType.matchType === 'string') {
                                                                return mtc.matchType === matchType.matchType;
                                                            } else {
                                                                return matchType.matchType.includes(mtc.matchType)
                                                            }
                                                        })?.count || 0}
                                                    </Typography>
                                                </Link>
                                            </ShTooltip>
                                        </> : <>
                                            <Typography>
                                                {summary.matchTypeCounts?.find(mtc => {
                                                    if (typeof matchType.matchType === 'string') {
                                                        return mtc.matchType === matchType.matchType;
                                                    } else {
                                                        return matchType.matchType.includes(mtc.matchType)
                                                    }
                                                })?.count || 0}
                                            </Typography>
                                        </>
                                    }
                                </Box>
                            ))}
                            <Box className='job-detail toggle-in-dark'>
                                <IncompleteIcon />
                                <Typography variant='subtitle2' className='label'>Incomplete</Typography>
                                <ShTooltip title="View incomplete applicants list" placement='right-start'>
                                    <Link component={RouterLink} to={`/employer/incomplete-applicants/${job.id}`}
                                        onClick={gotoIncompleteApplicants}>
                                        <Typography>{summary?.inCompleteApplicantsCount || 0}</Typography>
                                    </Link>
                                </ShTooltip>
                            </Box>
                        </>}

                    </Stack>
                    {!isXsScreen && summary?.applicantList && summary?.applicantList.length > 0 &&
                        <Box width={{ sm: '65%', md: '73%', lg: '55%', xl: '60%' }} >
                            <Typography variant="subtitle1" align="center" gutterBottom>
                                Job Performance
                            </Typography>
                            <ResponsiveChartContainer
                                series={[{
                                    type: 'line',
                                    color: ShGreen,
                                    data: chartData.y,
                                    valueFormatter: (value) => `${value} Candidates`,
                                    label: "Number of Candidates Applied: "
                                }]}
                                xAxis={[{
                                    data: chartData.x.map((date) => format(new Date(date), 'yyyy-MM-dd')),
                                    scaleType: 'band',
                                    id: 'x-axis-id',
                                }]}
                                height={200}>
                                <LinePlot />
                                <ChartsYAxis labelFontSize={12} label="Number of Candidates" position="left" />
                                <ChartsXAxis valueFormatter={(date: string) => dayjs(date).format("MMM D")} />
                                <ChartsTooltip formatter={(params: string) => `${params.valueOf} # Candidates Applied`} />
                            </ResponsiveChartContainer>
                        </Box>
                    }
                    {!isMdScreen &&
                        <Stack paddingX={{ xs: 3, sm: 2, md: 3, lg: 1, xl: 5 }}>
                            <JobActions key={job.id} job={job} jobsType={jobsType} actionMenuType='widget' />
                        </Stack>
                    }
                </Stack>
            </>}
        </JobSummaryStack>
    </>);
};