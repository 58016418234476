import ReactQuill from 'react-quill';
import styled from 'styled-components';

export const ShReactQuillStyled = styled(ReactQuill)(({ theme }) => ({
    height: '100%',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    '& .ql-container': {
        flex: 1,
        '& .ql-editor': {
            minHeight: '130px'
        }
    },
    '& .tooltip-button': {
        'position': 'relative',

        '&::after': {
            'content': 'attr(data-tooltip)',
            'position': 'absolute',
            'bottom': '100%',
            'left': '50%',
            'transform': 'translateX(-50%)',
            'backgroundColor': '#333',
            'color': '#fff',
            'padding': '5px 10px',
            'borderRadius': '5px',
            'whiteSpace': 'nowrap',
            'opacity': 0,
            'pointerEvents': 'none',
            'transition': 'opacity 0.2s ease',
            'fontSize': '12px',
            'visibility': 'hidden',
        },

        '&:hover::after': {
            'opacity': 1,
            'visibility': 'visible',
        }
    }
}));

