import { Typography } from "@mui/material";
import { addDays, differenceInDays } from 'date-fns';
import { useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { ShBanner } from "./Banner.styled";

export const Banner = () => {

    const { employerDetails } = useAppSelector(state => state.employer.employerDetails);
    const [couponExpiryDay, setCouponExpiryDay] = useState<number>(0);

    useEffect(() => {
        if (employerDetails?.createdAt) {
            const lastDate = addDays(new Date(employerDetails.createdAt), 30);
            const remainingDays = differenceInDays(lastDate, new Date(employerDetails.createdAt));
            if (remainingDays) {
                setCouponExpiryDay(remainingDays)
            }
        }
    }, [employerDetails?.createdAt]);

    return (<>
        <ShBanner>
            {couponExpiryDay &&
                <Typography variant="body1" textAlign='center'>Your Free Trial coupon expires in {couponExpiryDay} days</Typography>
            }
        </ShBanner>
    </>)
}