import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Alert, Box, Divider, FormControl, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { IApplicant, IApplicantsApiParams } from "Modules/Core/Applicants/ApplicantsModel";
import { IJobsApiParams } from "Modules/Core/JobsTs/JobsModel";
import { useCallback, useEffect, useState } from "react";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { fetchDashboardActiveJobsList } from "store/slices/employer/employer/dashboard/dashboard-active-jobs-slice";
import { fetchDashboardApplicantsList } from "store/slices/employer/employer/dashboard/dashboard-applicants-slice";
import { setSelectedJobId } from "store/slices/employer/employer/dashboard/dashboard-recent-activity-selected-job-slice";
import { DashboardMenuProps, ShTimeline, ShTimelineItem } from "../Dashboard.styled";
import { DashboardListSize } from "../DashboardConstants";
import { truncateText } from 'shared/utils';

export const DashboardRecentActivityWidget = () => {
    const [selectedJob, setSelectedJob] = useState<number>(0);
    const [selectedJobName, setSelectedJobName] = useState<string>("");

    const { jobsList: activeJobsList, getJobsListStatus } = useAppSelector(state => state.employer.dashboard.dashboardActiveJobs);
    const { applicantsList: applicantsData } = useAppSelector((state) => state.employer.dashboard.dashboardApplicants);
    const { selectedJobId } = useAppSelector(state => state.employer.dashboard.selectedJobForRecentActivity);

    const dispatch = useAppDispatch();
    const getJobs = useCallback(() => {
        const params: IJobsApiParams = {
            jobType: 'active',
            pageNumber: 1,
            pageSize: DashboardListSize,
            sortDirection: 'desc',
            sortColumn: 'created_at',
            search: '',
        };
        // FOR FETCHING DRAFT JOBS
        dispatch(fetchDashboardActiveJobsList(params));
    }, [dispatch]);

    // Get jobs list on page load.
    useEffect(() => {
        getJobs();
    }, [dispatch, getJobs]);

    const getApplicants = useCallback((jobId: number) => {
        const params: IApplicantsApiParams = {
            jobId: jobId,
            pgNo: 1,
            pgSize: 50,
            sortCol: 'first_name',
            sortDir: 'asc',
            search: '',
        };

        dispatch(fetchDashboardApplicantsList(params));
    }, [dispatch]);

    // Get applicants list when summary panel is toggled and has applicants.
    useEffect(() => {
        if (selectedJob !== 0) {
            getApplicants(selectedJob);
        }
    }, [getApplicants, selectedJob]);

    useEffect(() => {
        if (activeJobsList && activeJobsList.length > 0) {
            let jobIdToUse: number;

            if (selectedJobId !== null) {
                jobIdToUse = selectedJobId;
            }

            const selectedJobData = activeJobsList.find((job) => job.id === jobIdToUse);
            setSelectedJobName(selectedJobData?.name ?? '')
            if (selectedJobData) {
                dispatch(setSelectedJobId(selectedJobData.id));
                setSelectedJob(selectedJobData.id)
            } else {
                const firstActiveJob = activeJobsList[0];
                dispatch(setSelectedJobId(firstActiveJob.id));
            }
        }
    }, [activeJobsList, applicantsData, selectedJobId, dispatch]);


    const handleDashboardRecentActivityChange = (event: SelectChangeEvent<string>) => {
        const jobId = parseInt(event.target.value);
        if (jobId) {
            dispatch(setSelectedJobId(jobId));
        }

    };

    const getDotPropertiesAndMessage = (applicant: IApplicant) => {
        let dotColor: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' = 'primary';
        let dotVariant: 'filled' | 'outlined' = 'outlined';
        let message = `${applicant.firstName} was ${applicant.jobStage}`;

        switch (applicant.jobStage) {
            case 'new':
                dotColor = 'primary';
                dotVariant = 'filled';
                message = `${applicant.firstName} ${applicant.lastName} has applied to ${selectedJobName}`;
                break;
            case 'reviewed':
                dotColor = 'primary';
                dotVariant = 'filled';
                message = `${applicant.firstName} ${applicant.lastName} has been reviewed`;
                break;
            case 'interview':
                dotColor = 'success';
                dotVariant = 'outlined';
                message = `${applicant.firstName} ${applicant.lastName} is scheduled to be interviewed`;
                break;
            case 'second_interview':
                dotColor = 'success';
                dotVariant = 'outlined';
                message = `${applicant.firstName} ${applicant.lastName} 2nd interview has been scheduled`;
                break;
            case 'offer':
                dotColor = 'success';
                dotVariant = 'filled';
                message = `${applicant.firstName} ${applicant.lastName} was given an offer for ${selectedJobName}`;
                break;
            case 'hired':
                dotColor = 'success';
                dotVariant = 'filled';
                message = `${applicant.firstName} ${applicant.lastName} was hired for ${selectedJobName} 🎉`;
                break;
            case 'phone_screening':
                dotColor = 'primary';
                dotVariant = 'outlined';
                message = `${applicant.firstName} ${applicant.lastName} has been scheduled for a phone interview`;
                break;
            case 'rejected':
                dotColor = 'error';
                dotVariant = 'filled';
                message = `${applicant.firstName} ${applicant.lastName} was rejected from ${selectedJobName}`;
                break;
            case 'not_qualified':
                dotColor = 'error';
                dotVariant = 'filled';
                message = `${applicant.firstName} ${applicant.lastName} has been categorized as not qualified for ${selectedJobName}`;
                break;
            case 'auto_rejected':
                dotColor = 'error';
                dotVariant = 'filled';
                message = `${applicant.firstName} ${applicant.lastName} has been auto rejected for ${selectedJobName}`;
                break;
        }

        return { dotColor, dotVariant, message };
    };

    /*
    * If the applicant is new, then use the completedAtDate when sorting, otherwise use the stageModifiedAt Date
    */
    const sortedApplicantList = [...applicantsData].sort((a, b) => {
        const dateA = (a.jobStage === 'new') ? new Date(a.applicationCompletedAt) : new Date(a.stageModifiedAt);
        const dateB = (b.jobStage === 'new') ? new Date(b.applicationCompletedAt) : new Date(b.stageModifiedAt);
        return dateB.getTime() - dateA.getTime();
    });

    return (
        <ShPaper height='100%' marginBottom='10px' variant='outlined' >
            <Stack direction='row' justifyContent='space-between' padding={1}>
                <Typography variant='subtitle1' fontWeight='bold'> Recent Activity</Typography>
                <Box width={150} >
                    <FormControl fullWidth size="small" disabled={activeJobsList.length === 0}>
                            <InputLabel style={{fontSize:'small'}}>{activeJobsList.length > 0 ? 'Select a Job' : 'No Active Jobs'}</InputLabel>
                            <Select
                                style={{ fontSize: 'small' }}
                                MenuProps={DashboardMenuProps}
                                onChange={handleDashboardRecentActivityChange}
                                value={activeJobsList.length > 0 ? selectedJob.toString() : ''}
                                input={<OutlinedInput label={activeJobsList.length > 0 ? 'Select a JobXX' : ''} />}
                            >
                                {activeJobsList.map((job) => (
                                    <MenuItem style={{ fontSize: 'small' }} key={job.id} value={job.id}>
                                        {truncateText(job.name, 30)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                </Box>
            </Stack>
            {getJobsListStatus === "pending" ?<> <LinearProgress /></> :
                <Stack maxHeight='100%' height='100%' overflow='auto'>
                    {sortedApplicantList?.length > 0 ?
                        <>
                        <Divider/>
                        <Stack direction='row' marginBottom={2} maxWidth={800} justifyContent='center'>
                                <ShTimeline>
                                    {sortedApplicantList.map((applicant, index) => {
                                        const { dotColor, dotVariant, message } = getDotPropertiesAndMessage(applicant);
                                        return (
                                            <ShTimelineItem key={index}>
                                                <TimelineOppositeContent color="text.secondary">
                                                    <ShChip label={new Date(applicant.stageModifiedAt).toLocaleString([], { weekday: 'short', hour: '2-digit', minute: '2-digit' })} />
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color={dotColor} variant={dotVariant} />
                                                    {index !== sortedApplicantList.length - 1 && <TimelineConnector />}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Typography fontSize="14px">
                                                        {message}
                                                    </Typography>
                                                </TimelineContent>
                                            </ShTimelineItem>
                                        );
                                    })}
                                </ShTimeline>
                        </Stack>
                        </>
                        :
                        <Alert severity="info">No Recenty Activity</Alert>
                    }
                </Stack>
            }
        </ShPaper>
    )
}