import React from 'react';
import { ShChip } from 'shared/SharedStyles/ShDataDisplay';
import { PrimaryWordpressThemeColor, ShGreen } from 'shared/SharedStyles/styleConstants';

interface ChipDisplayProps {
    name: string;
  }

export const ChipDisplay: React.FC<ChipDisplayProps> = ({ name }) => {
  // Define colors based on the package name
  let color: 'success' | "default" | "primary" | "secondary" = "default";

  switch (name) {
    case "Basic Job Posting Package":
        color = "primary"; 
        return <ShChip bgColor={PrimaryWordpressThemeColor} size="small" color={color} label={name} />;
    case "Candidate Fit Package":
        color = "secondary"; 
        return <ShChip size="small" label={name} />;
    case "Sponsored Job Posting Package":
      color = 'success'; 
      return <ShChip textColor='white' bgColor={ShGreen} size="small" color={color} label={name + " (Popular)"} />;
    case "LinkedIn":
    color = 'success'; 
    return <ShChip size="small" label={name + " Add-on"} />;
    default:
      color = "default"; 
  }

  return <ShChip size="small" color={color} label={name} />;
};

export default ChipDisplay;
