import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Divider, FormControlLabel, Grid, Radio, Stack, Typography } from "@mui/material";
import { BundleBox, BundleFormControlLabel } from 'Modules/Core/CreateJob/CreateJob.styled';
import { JobValidity, TestimonialData } from "Modules/Core/CreateJob/CreateJobConstants";
import { IBundle } from 'Modules/Core/CreateJob/CreateJobModel';
import { FAQ } from 'Modules/Core/FAQ/FAQ';
import { useNotification } from 'Modules/Core/Notification';
import { IsXsScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmationDialog } from 'shared/SharedComponents/ConfirmationDialog/ConfirmationDialog';
import { ApiState } from 'shared/SharedModels';
import { ShCheckmark } from 'shared/SharedStyles/ShDataDisplay';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn, ShGreenCheckbox, ShRadioGroup } from 'shared/SharedStyles/ShInputs';
import { ShGreen } from 'shared/SharedStyles/styleConstants';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { updateArrayById } from 'shared/utils';
import {
    addJobToCart, getAllBundles, getInventoryBundles, resetAddToCart, resetBundles,
    setSelectedAddOnBundles,
    setSelectedBaseBundle,
    setTotalJobCost
} from 'store/slices/employer/create-job/create-job-payments-slice';
import { setIsPaymentEnabled } from 'store/slices/employer/create-job/create-job-shared-slices';
import ChipDisplay from './BundlesHelper';

export const Bundles = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { jobId } = useParams();
    const bundlesStackRef = useRef<HTMLDivElement>(null);
    const isXsScreen = IsXsScreen();
    const { allBundles, inventoryBundles, getAllBundlesResponse, getAllBundlesStatus, getInventoryBundlesResponse,
        addJobToCartStatus, addJobToCartResponse, getInventoryBundlesStatus, selectedBaseBundle, selectedAddOnBundles,
        allBaseBundles, allAddOnBundles } = useAppSelector((state) => state.employer.createJob.createJobPayments);
    const { currentJobCreationFlow } = useAppSelector((state) => state.employer.createJob.createJobShared);
    const { employerDetails } = useAppSelector(state => state.employer.employerDetails);
    const [_selectedBaseBundle, _setSelectedBaseBundle] = useState<number>(0);
    const [_selectedAddOnBundles, _setSelectedAddOnBundles] = useState<number[]>([]);
    const [_totalJobCost, _setTotalJobCost] = useState<number>(0);
    const [openNoBundleSelectedDialog, setOpenNoBundleSelectedDialog] = useState<boolean>(false);
    const [isBackClicked, setIsBackClicked] = useState<boolean>(false);

    // scroll to the top of bundles on loading this page.
    useEffect(() => {
        bundlesStackRef?.current?.scrollIntoView();
    }, []);

    // Update selected bundles if previously selected.
    useEffect(() => {
        if (selectedBaseBundle !== undefined) {
            _setSelectedBaseBundle(selectedBaseBundle);
        }
    }, [selectedBaseBundle]);

    useEffect(() => {
        if (selectedAddOnBundles?.length > 0) {
            _setSelectedAddOnBundles(selectedAddOnBundles);
        }
    }, [selectedAddOnBundles]);

    // Fetch all bundles on page load.
    useEffect(() => {
        if (allBundles.length === 0) {
            dispatch(getAllBundles());
        }
        // reset on unmount
        return () => {
            dispatch(resetBundles());
        }
    }, [allBundles.length, dispatch]);

    // Fetch inventory bundles on page load.
    useEffect(() => {
        if (inventoryBundles?.length === 0) {
            dispatch(getInventoryBundles());
        }
    }, [dispatch, inventoryBundles?.length]);

    // Temporarily no default bundles are selected.
    // useEffect(() => {
    //     if (allBundles?.length > 0 && !selectedBundles?.length) {
    //         const defaultBundle = updateArrayById<number, number>(allBundles.find(b => b.kind === 'base' && b.name !== 'ATS')?.id ?? 0, []);
    //         _setSelectedBundles(defaultBundle);
    //         dispatch(setSelectedBundles(defaultBundle));
    //     }
    // }, [allBundles, dispatch, getAllBundlesStatus, selectedBundles?.length]);

    useEffect(() => {
        if (employerDetails?.free_trail_signup && allAddOnBundles?.length > 0) {
            // Filtering LinkedIn for free trial.
            const addOnBundleIds = allAddOnBundles.filter(ab => ab.name !== 'LinkedIn').map(ab => ab.id);
            _setSelectedAddOnBundles([...addOnBundleIds]);
            dispatch(setSelectedAddOnBundles([...addOnBundleIds]));
        }
    }, [allAddOnBundles, dispatch, employerDetails?.free_trail_signup]);

    // Calculate total on selected bundles change.
    useEffect(() => {
        let totalCost = 0;
        [_selectedBaseBundle, ..._selectedAddOnBundles].forEach(bId => {
            // Count total only if the selected bundle is not present in inventory.
            if (inventoryBundles.find(iB => iB.bundle_id === bId) === undefined) {
                totalCost += allBundles.find(b => b.id === bId)?.base_price ?? 0
            }
        });
        // const atsId = allBundles.find(bundles => bundles.name === 'Basic Job Posting Package')?.id;
        // if (atsId) {
        //     setIsAtsSelected(_selectedBundles?.includes(atsId))
        // }
        _setTotalJobCost(totalCost);
        // Update state if all bundles are unselected     
        if (_selectedAddOnBundles?.length === 0) {
            dispatch(setSelectedAddOnBundles([]));
        }
    }, [_selectedAddOnBundles, _selectedBaseBundle, allBundles, dispatch, inventoryBundles]);

    // Go to payments only if job is added to cart.
    useEffect(() => {
        if (addJobToCartStatus === 'success') {
            displayNotification({
                open: true,
                type: 'success',
                message: addJobToCartResponse ?? ''
            });
            dispatch(resetAddToCart());
            dispatch(setIsPaymentEnabled(true));
            // dispatch(getAllBundles());
            // dispatch(getInventoryBundles());
            if (isBackClicked) {
                if (currentJobCreationFlow === 'useTemplateJob') {
                    navigate(`/employer/job/${jobId}/title`);
                } else {
                    navigate(`/employer/job/${jobId}/preview`);
                }
            } else {
                navigate(`/employer/job/${jobId}/payment`);
            }
        }
    }, [addJobToCartResponse, addJobToCartStatus, currentJobCreationFlow, dispatch, displayNotification, isBackClicked, jobId, navigate]);

    const getPricingLabel = (bundle: IBundle) => {
        const bundleInInventory = inventoryBundles?.find(ib => ib.bundle_id === bundle.id);
        return (<>
            {
                bundleInInventory ?
                    <Typography variant='body1' fontWeight={600} textAlign={isXsScreen ? 'right' : 'center'} width='100%'>
                        {`1 of ${bundleInInventory?.quantity} credit${bundleInInventory?.quantity > 1 ? 's' : ''}`}
                    </Typography> :
                    bundle.base_price > 0 &&
                    <Typography variant='body1' fontWeight={600} textAlign={isXsScreen ? 'right' : 'center'} width='100%'>
                        ${bundle.base_price}</Typography>
            }
        </>);
    };

    const showFailureAlerts = (apiState: ApiState, msg: string, apiPendingMsg: string) => {
        switch (apiState) {
            case 'pending':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="info">{apiPendingMsg}</ShAlert>
                    </Box>
                </>);
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };

    const continueToPayment = () => {
        dispatch(setTotalJobCost(_totalJobCost));
        if (_selectedBaseBundle) {
            dispatch(setSelectedBaseBundle(_selectedBaseBundle));
        }
        dispatch(setSelectedAddOnBundles(_selectedAddOnBundles));

        // Only add to cart if a job is selected, 
        // otherwise since the continue to payment is called on JobBundlesBack, go back to preview.
        if (_selectedBaseBundle) {
            dispatch(addJobToCart({ jobId: parseInt(jobId ?? ''), payload: { bundles: [_selectedBaseBundle, ..._selectedAddOnBundles] } }));
        } else if (!_selectedBaseBundle) {
            if (currentJobCreationFlow === 'useTemplateJob') {
                navigate(`/employer/job/${jobId}/title`);
            } else {
                navigate(`/employer/job/${jobId}/preview`);
            }
        }
    };

    const onJobBundlesBack = () => {
        setIsBackClicked(true);
        continueToPayment();
    }

    return <>
        <Stack direction={{ lg: "row" }} ref={bundlesStackRef} paddingTop={1} paddingX={{ xs: 0, sm: 2, md: 3, lg: 3 }}>
            <Stack maxWidth={800}>
                {allBaseBundles.find(abb => abb.employer_pricing_group_id === 8) !== undefined &&
                    <Typography variant='body1' textAlign='center' marginBottom={2}>Select either an Basic Package or a Candidate fit package.</Typography>
                }
                {/* Error alerts */}
                {showFailureAlerts(getAllBundlesStatus, getAllBundlesResponse, 'Loading Bundles...')}
                {showFailureAlerts(getInventoryBundlesStatus, getInventoryBundlesResponse, 'Loading Inventory Bundles...')}
                {showFailureAlerts(addJobToCartStatus ?? 'idle', addJobToCartResponse ?? DefaultAPIErrorMsg, 'Adding Job to Cart...')}
                <ShRadioGroup width='100%' color={ShGreen}
                    aria-labelledby="base-package-selection"
                    name="base-package-selection-group"
                    value={_selectedBaseBundle} onChange={(e) => _setSelectedBaseBundle(Number(e.target.value))}>
                    {allBaseBundles.map((bundle, indx, arr) => (
                        <Stack key={bundle.id}>
                            <FormControlLabel id={bundle.id?.toString()} aria-label={bundle.name}
                                className={_selectedBaseBundle === bundle.id ? 'selected' : ''}
                                labelPlacement="start" value={bundle.id}
                                control={<Radio />}
                                label={
                                    <BundleBox>
                                        <Grid container columnSpacing={1} rowGap={2}>
                                            <Grid item xs={12} sm={8} md={8} lg={8}>
                                                <Box paddingTop={1} paddingBottom={1}>
                                                    <ChipDisplay name={bundle.name} />
                                                </Box>
                                                {/* 
                                                    Bundle description is being sent as '-' separated string from the API.
                                                    Split it with '-' and display as a Bullet points.
                                                */}
                                                <ShCheckmark items={bundle.description.split(/(?=[-~])/)} />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={4} display='flex' alignItems='center' justifyContent='center'>
                                                {/* 
                                                    If bundles are already available in inventory show the credits deducted label.
                                                    If no bundles, then display bundle price.
                                                */}
                                                {!employerDetails?.free_trail_signup && getPricingLabel(bundle)}
                                            </Grid>
                                        </Grid>
                                    </BundleBox>
                                } />
                            {indx !== arr.length - 1 && <Box marginY={1}><Divider>Or</Divider></Box>}
                        </Stack>
                    ))}
                </ShRadioGroup>
                {allAddOnBundles.filter(ab => employerDetails?.free_trail_signup ? ab.name !== 'LinkedIn' : true)?.map(bundle => (
                    <BundleFormControlLabel key={bundle.id} id={bundle.name} aria-label={bundle.name}
                        className={_selectedAddOnBundles?.includes(bundle.id) ? 'selected-package' : ''}
                        labelPlacement="start" value={bundle.id}
                        control={
                            employerDetails?.free_trail_signup ?
                                <Typography variant='body2' color={ShGreen} fontWeight={'bold'}>Included</Typography> :
                                <ShGreenCheckbox name={bundle.name}
                                    disabled={!_selectedBaseBundle}
                                    checked={_selectedAddOnBundles?.includes(bundle.id)}
                                    onChange={() => _setSelectedAddOnBundles(updateArrayById<number, number>(bundle.id, _selectedAddOnBundles))} />}
                        label={
                            <BundleBox>
                                <Grid container columnSpacing={1} rowGap={2}>
                                    <Grid item xs={12} sm={8} md={8} lg={8}>
                                        <Box paddingTop={1} paddingBottom={1}>
                                            <ChipDisplay name={bundle.name} />
                                        </Box>
                                        {/* 
                                            Bundle description is being sent as '-' separated string from the API.
                                            Split it with '-' and display as a Bullet points.
                                        */}
                                        <ShCheckmark items={bundle.description.split(/(?=[-~])/)} />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={4} display='flex' alignItems='center' justifyContent='center'>
                                        {/* 
                                            If bundles are already available in inventory show the credits deducted label.
                                            If no bundles, then display bundle price.
                                        */}
                                        {!employerDetails?.free_trail_signup && getPricingLabel(bundle)}
                                    </Grid>
                                </Grid>
                            </BundleBox>
                        }
                    />
                ))}
                {!employerDetails?.free_trail_signup &&
                    <Stack flexDirection='row' alignItems='center' columnGap={0.5} mb={2}>
                        <InfoOutlinedIcon fontSize="small" color='disabled' />
                        <Typography variant="caption" display='flex' alignItems='center'>
                            Jobs active for {JobValidity} days
                        </Typography>
                    </Stack>}
                {/* Actions buttons */}
                <Stack flexDirection='row' justifyContent='space-between' alignItems='center'
                    columnGap={1} rowGap={1} mb={3} flexWrap='wrap'>
                    <ShButton variant='contained' size='small' disableElevation onClick={onJobBundlesBack}>Back</ShButton>
                    {/* <ShButton startIcon={<BalanceIcon />} onClick={() => dispatch(togglePricingDialog())}>Full Comparison</ShButton> */}
                    {!employerDetails?.free_trail_signup &&
                        <Typography variant="body2" whiteSpace='nowrap' fontWeight={600}>Total: ${_totalJobCost}</Typography>}

                    <ShGreenBtn disableElevation variant='contained' className='continue-btn' disabled={!_selectedBaseBundle}
                        onClick={() => {
                            _selectedAddOnBundles.length === 0 ? setOpenNoBundleSelectedDialog(true) :
                                continueToPayment();
                        }}>Continue</ShGreenBtn>
                </Stack>
                <Divider />
                <Stack my={1}> {/* Adjusted margin-y here for more spacing between FAQ  and breakdown */}
                    <Typography variant='h5' textAlign='center' gutterBottom>
                        FAQ's
                    </Typography>
                    <FAQ />
                </Stack>
            </Stack>
            <Divider />
            <Stack px={{ lg: 6 }} my={{ xs: 2, lg: 0 }} width='100%' textAlign='center'>
                <Typography variant='h6' gutterBottom >Testimonials</Typography>
                {TestimonialData.map((item, index) => (
                    <Box key={index}>
                        <Box my={2}>
                            <Typography variant='body2' mb={1.5}>"{item.review}"</Typography> {/* Adjusted margin-bottom here */}
                            <Typography variant='body2' fontWeight="bold" gutterBottom>~ {item.name}</Typography>
                        </Box>
                        <Divider />
                    </Box>
                ))}
            </Stack>
        </Stack>
        <ConfirmationDialog onCancel={() => setOpenNoBundleSelectedDialog(false)}
            onConfirm={continueToPayment} confirmButtonLabel="Continue" cancelButtonLabel="Back"
            isDialogOpen={openNoBundleSelectedDialog} title={"WHOA!"}
            contentText="No job posting package selected. If you're not looking to post or promote a job, 
        click 'Continue', Otherwise, please click 'back' to select a package"></ConfirmationDialog>
    </>;
};

