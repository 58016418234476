import { AppLogin } from "Modules/Core/AppLogin/AppLogin"
import { AppLoginWrapper } from "Modules/Core/AppLogin/AppLoginWrapper"
import { EmployerSignUp } from "Modules/Core/AppLogin/EmployerSignUp"
import { ResetPassword } from "Modules/Core/AuthV2/ResetPassword"
import { SocialLogin } from "Modules/Core/AuthV2/SocialLogin"
import { ErrorPage } from "Modules/Core/RedirectPages/ErrorPage"
import { Navigate, Route, Routes } from "react-router-dom"

export const AuthRouter = () => {
    return (<>
        <Routes>
            {/* Employer login */}
            <Route path="/" element={<AppLoginWrapper />}>
                {/* Error 404 page */}
                <Route path="*" element={<ErrorPage />} />
                <Route path="/" element={<Navigate replace to="/accounts/login" />} />
                <Route path="/login" element={<AppLogin />} />
                <Route path="/login/:provider/:status" element={<SocialLogin buttonType="icon" usedIn="employer" />} />
                <Route path="/sign-up" element={<EmployerSignUp />} />
                {/* Password reset component */}
                <Route path="/password/reset" element={<ResetPassword />} />
            </Route>
        </Routes>
    </>)
}