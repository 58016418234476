import InfoIcon from '@mui/icons-material/Info';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { AddUserDialog } from 'Modules/Core/SettingsTs/AccountSettings/AddUserDialog';
import { AccessLevelBullets } from 'Modules/Core/SettingsTs/SettingsConstants';
import { IAccount } from 'Modules/Core/SettingsTs/SettingsModel';
import { IsMdScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShTooltip } from 'shared/SharedComponents/ShTooltip';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import {
    getAccountList, getRoles, resetAddUser, resetDeleteUser, resetUpdateUserRoles
} from 'store/slices/employer/settingsTs/access-levels-slice';
import { AccessLevelListView } from './AccessLevelListView';
import { AccessLevelsTableView } from './AccessLevelsTableView';

export const AccessLevels = () => {
    const dispatch = useAppDispatch();
    const isMdScreen = IsMdScreen();
    const { employerId, accountAccess } = useAppSelector((state) => state.auth.auth);
    const { getAccountListApiStatus, getAccountListApiResponse, accountList, addUserApiStatus, updateRolesApiStatus,
        getRolesListApiStatus, getRolesListApiResponse, updateRolesApiResponse, deleteUserApiResponse,
        deleteUserApiStatus, rolesList
    } = useAppSelector((state) => state.employer.employerSettings.accessLevels);
    const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState<boolean>(false);
    const [_accountList, _setAccountList] = useState<IAccount[]>([]);
    const [existingUser, setExistingUser] = useState<IAccount | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [paginatedAccountList, setPaginatedAccountList] = useState<IAccount[]>([]);
    const navigate = useNavigate();

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        // Update the data for pagination
        const startIndex = (currentPage - 1) * 10;
        const endIndex = startIndex + 10;
        setPaginatedAccountList(_accountList.slice(startIndex, endIndex));
    }, [_accountList, currentPage]);

    useEffect(() => {
        if (rolesList?.length === 0) {
            dispatch(getRoles());
        }
        return () => {
            // Reset update company info, logoAPIvariables on component unmount.
            dispatch(resetUpdateUserRoles());
        }
    }, [dispatch, rolesList?.length]);

    // load account list.
    useEffect(() => {
        if (accountList?.length === 0) {
            dispatch(getAccountList());
        }
    }, [accountList?.length, dispatch]);

    // re-load account list when role is updated.
    useEffect(() => {
        if (updateRolesApiStatus === 'success') {
            dispatch(getAccountList());
        }
    }, [dispatch, updateRolesApiStatus]);

    // set account list.
    useEffect(() => {
        _setAccountList(accountList);
    }, [accountList, dispatch]);

    // Edit user handler which gets invoked from Row component.
    const editUser = (user: IAccount) => {
        // Update the 'existingUser' variable with the User's data that's going to be edited.
        setExistingUser({ ...user });
        setIsAddUserDialogOpen(true);
    };

    // Update table data if user is successfully added.
    useEffect(() => {
        if (addUserApiStatus === 'success') {
            dispatch(getAccountList());
        }
    }, [addUserApiStatus, dispatch]);

    // Update table data if user is successfully removed.
    useEffect(() => {
        if (deleteUserApiStatus === 'success') {
            dispatch(getAccountList());
        }
    }, [deleteUserApiStatus, dispatch]);

    const AlertForRolesUpdate = () => {
        switch (updateRolesApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Updating Roles...</ShAlert>);
            case 'success':
                return (<ShAlert severity="success" onClose={() => dispatch(resetUpdateUserRoles())}>
                    <Typography fontWeight={600}>{updateRolesApiResponse}</Typography>
                </ShAlert>);
            case 'failed':
                return (<ShAlert severity="error">{updateRolesApiResponse}</ShAlert>);
            default:
                return (<></>);
        }
    };

    const AlertForUserDelete = () => {
        switch (deleteUserApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Deleting User...</ShAlert>);
            case 'success':
                return (<ShAlert severity="success" onClose={() => dispatch(resetDeleteUser())}>
                    <Typography fontWeight={600}>{deleteUserApiResponse}</Typography>
                </ShAlert>);
            case 'failed':
                return (<ShAlert severity="error">{deleteUserApiResponse}</ShAlert>);
            default:
                return (<></>);
        }
    };

    const closeAddUserDialog = () => {
        setIsAddUserDialogOpen(false);
        /*
            If 'existingUser' is not undefined i.e., closeAddUserDialog is called after a user edit, set existingUser to undefined.
        */
        if (existingUser) {
            setExistingUser(undefined);
        }
    };

    // Reset add user and update user state on component unmount.
    useEffect(() => {
        return () => {
            dispatch(resetUpdateUserRoles());
            dispatch(resetAddUser());
            dispatch(resetDeleteUser());
        };
    }, [dispatch]);

    useEffect(() => {
        if (accountAccess?.role !== 'admin' && accountAccess?.role !== 'owner') {
            navigate("/employer/dashboard");
        }
    }, [accountAccess?.role, navigate])

    return (
        <>
            <Stack minHeight='350px'>
                {/* Alerts for account and roles listAPIerrors */}
                {getAccountListApiStatus === 'failed' && <ShAlert severity="error">
                    {getAccountListApiResponse ?? 'Error occurred while fetching Account List'}
                </ShAlert>}
                {getRolesListApiStatus === 'failed' && <ShAlert severity="error">
                    {getRolesListApiResponse ?? 'Error occurred while fetching Roles List'}
                </ShAlert>}
                <Typography component={'span'} variant='body2'>
                    <Stack columnGap={1} flexDirection='row' alignItems='center'>
                        Be the President of your account by using your owner status to
                        add more people and assign their access levels.<ShTooltip
                            title={<ul>{AccessLevelBullets.map(aLB => (<li key={aLB}>{aLB}</li>))}</ul>}
                            placement="right"><InfoIcon fontSize="small" color='disabled' /></ShTooltip></Stack>
                </Typography>
                <Stack marginTop={2} marginBottom={2} flexDirection='row' justifyContent='flex-end'>
                    {accountAccess?.role !== 'admin' &&
                        <ShButton disableElevation variant='contained' onClick={() => setIsAddUserDialogOpen(true)}>
                            <Typography variant='body2'>Add Users</Typography>
                        </ShButton>}
                    {/* Add User Dialog */}
                    {employerId &&
                        <AddUserDialog accountList={accountList} closeAddUserDialog={closeAddUserDialog}
                            employerId={employerId} _accountList={_accountList}
                            isAddUserDialogOpen={isAddUserDialogOpen} user={existingUser} />
                    }
                </Stack>
                {getAccountListApiStatus === 'pending' ? <LinearProgress /> : <>
                    {_accountList?.length > 0 ?
                        <>
                            {updateRolesApiStatus !== 'idle' && <Box marginBottom={2}>
                                {AlertForRolesUpdate()}
                            </Box>}
                            {deleteUserApiStatus !== 'idle' &&
                                <Box marginBottom={2}>
                                    {AlertForUserDelete()}
                                </Box>
                            }
                            {isMdScreen ? <>
                                <AccessLevelListView editUser={editUser} paginatedAccountList={paginatedAccountList} />
                            </> : <>
                                <AccessLevelsTableView editUser={(user) => editUser(user)} paginatedAccountList={paginatedAccountList} />
                            </>}

                            {/* Display Pagination Controls only if there are more than 10 records*/}
                            {Math.ceil(_accountList.length / 10) > 1 &&
                                <Stack alignItems='flex-end' padding={1}>
                                    <Pagination
                                        count={Math.ceil(_accountList.length / 10)}
                                        page={currentPage}
                                        onChange={(e, value) => handlePageChange(value)}
                                        color="primary"
                                    />
                                </Stack>}
                        </>
                        : getAccountListApiStatus === 'success' && <>No accounts found</>}
                </>}
            </Stack>
        </>
    );
}
