import { Box, Stack, Typography } from "@mui/material";
import { ShSettingsIcon } from "assets/Icons";
import { IsMdScreen, IsXsScreen } from "helpers/hooks";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { Link as RouterLink } from 'react-router-dom';
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShIconPrimary, ShIconSecondary, WhiteColor } from "shared/SharedStyles/styleConstants";
import CommonQuestionSidebar from "../CommonQuestionSidebar";

export const AccountSecurity = () => {

  const isMdScreen = IsMdScreen();
  const paddingValue = IsXsScreen() ? 1 : 3;

  return (
    <>
      <Stack padding={paddingValue} direction={'row'}>
        <ShContainer margin={'auto'}>
          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Account Security</ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            To enhance the security of your account on our ATS platform, you can enable various security features. Navigate to the
            <ShButton component={RouterLink} to="/employer/settings/account/security" color='inherit' variant="text"
              startIcon={<ShSettingsIcon primaryColor={ShIconPrimary} secondaryColor={ShIconSecondary} />}>
              Security
            </ShButton>
            tab in Settings to manage these options.
          </ResourceTypography>

          <ShButton component={RouterLink} to="/employer/settings/account/security" color='primary' variant="contained"
            startIcon={<ShSettingsIcon primaryColor={WhiteColor} secondaryColor={WhiteColor} />}>
            Manage Security
          </ShButton>

          <ResourceTypography paddingTop={2} variant="h6" fontWeight={'bold'} gutterBottom>Change Password</ResourceTypography>
          <Box paddingTop={3} paddingBottom={3}>
            <Stack spacing={2}>
              <Typography variant="body1" paragraph>
                You may change passwords by providing the old password and entering a new password.
              </Typography>
            </Stack>
          </Box>

          <ResourceTypography paddingTop={2} variant="h6" fontWeight={'bold'} gutterBottom>Two-Factor Authentication</ResourceTypography>
          <Box paddingTop={3} paddingBottom={3}>
            <Stack spacing={2}>
              <Typography variant="body1" paragraph>
                Enabling Two-Factor Authentication (2FA) provides an extra layer of security for your account.
                Once enabled, you will be required to enter a code emailed to you in addition to your password when logging in.
                You must also add a security question.
              </Typography>
            </Stack>
          </Box>

          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Last Login Details</ResourceTypography>
          <Box paddingTop={3} paddingBottom={3}>
            <Stack spacing={2}>
              <Typography variant="body1" paragraph>
                You can view the details of your last login, including the date, time, and location. This helps you monitor unauthorized access attempts and ensure the security of your account.
              </Typography>
            </Stack>
          </Box>

          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Strong Passwords</ResourceTypography>
          <Box paddingTop={3} paddingBottom={3}>
            <Stack spacing={2}>
              <Typography variant="body1" paragraph>
                We recommend using strong passwords to protect your account.
                A strong password includes a mix of uppercase and lowercase letters, numbers, and special characters.
                You can update your password in the Security tab.
              </Typography>
            </Stack>
          </Box>


        </ShContainer>
        {!isMdScreen && <CommonQuestionSidebar />}
      </Stack>
    </>
  );
};

export default AccountSecurity;
