import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Alert, Button, Divider, Stack, Typography } from '@mui/material';
import { useNotification } from 'Modules/Core/Notification';
import { TwoStepAuthType } from 'Modules/Core/SettingsTs/SettingsModel';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useCallback, useEffect, useState } from 'react';
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import { ApiState } from 'shared/SharedModels';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { updateUserAuthPreferences } from 'store/slices/auth-v2/auth-v2-slice';
import {
    getSecurityQuestions, resetAuthAnsChange, resetAuthQuestionChange, resetSecurityAuth,
    resetSetAuthQs, updateSecurityAuth
} from 'store/slices/employer/settingsTs/security-slice';
import { AuthQuestionDialog } from './AuthQuestionDialog';

export default function TwoStepAuth() {
    const dispatch = useAppDispatch();
    const notification = useNotification();
    const { twoStepAuth } = useAppSelector((state) => state.auth.auth);
    const { updateSecurityAuthApiStatus, updatedAuthPreferences, setAuthQsApiStatus,
        updateSecurityAuthApiResponse } = useAppSelector((state) => state.employer.employerSettings.security);
    const [isAuthQstnDlgOpen, setIsAuthQstnDlgOpen] = useState<boolean>(false);

    // Reusable notification function to show notifications and dispatch reset actions in slice. 
    const showNotification = useCallback((apiStatus: ApiState, resetFunc: Function, responseMsg?: string) => {
        notification.displayNotification({
            open: true,
            type: apiStatus === 'failed' ? 'error' : 'success',
            message: responseMsg ?? ''
        });
        dispatch(resetFunc());
    }, [dispatch, notification]);

    const openSecQstnModal = () => {
        setIsAuthQstnDlgOpen(true);
        dispatch(getSecurityQuestions());
    };

    // Update auth slice when user auth preferences change.
    const updateUserAuth = useCallback(() => {
        dispatch(updateUserAuthPreferences(updatedAuthPreferences));
    }, [dispatch, updatedAuthPreferences]);

    const updateSecAuth = (authType: TwoStepAuthType) => {
        if (authType === 'EMAIL_CODE') {
            dispatch(updateSecurityAuth({ security_varification: false, email_varification: true }));
        } else {
            openSecQstnModal();
        };
    };

    const SecurityAuthUpdateAlert = () => {
        switch (updateSecurityAuthApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Saving ...</ShAlert>);
            case 'failed':
                return (<ShAlert severity="error" onClose={() => dispatch(resetSecurityAuth())}>
                    {updateSecurityAuthApiResponse ?? 'Error occurred'}</ShAlert>);
            default:
                return (<></>);
        }
    };

    // Update user's new Auth preferences in auth slice.
    useEffect(() => {
        if (updateSecurityAuthApiStatus === 'success' || setAuthQsApiStatus === 'success') {
            updateUserAuth();
            showNotification('success', resetSecurityAuth, updateSecurityAuthApiResponse);
        }
    }, [setAuthQsApiStatus, showNotification, updateSecurityAuthApiResponse, updateSecurityAuthApiStatus, updateUserAuth]);

    // Reset on component unmount
    useEffect(() => {
        return () => {
            dispatch(resetAuthQuestionChange());
            dispatch(resetAuthAnsChange());
            dispatch(resetSecurityAuth());
            dispatch(resetSetAuthQs());
        }
    }, [dispatch]);

    return (
        <>
            <Stack rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }} paddingY={{ xs: 2, sm: 3, md: 4, lg: 4 }} maxWidth='450px'>
                {!twoStepAuth?.emailVerification && !twoStepAuth?.securityVerification &&
                    <Alert severity="warning">Two-Step Authentication removed. Your account/data may be vulnerable.</Alert>}
                {SecurityAuthUpdateAlert()}
                <Typography variant='body2'>
                    Add an extra layer of security to block unauthorized access
                    and protect your account
                </Typography>
                <Stack rowGap={2} alignItems='center'>
                    <Stack columnGap={1} flexDirection='row' alignItems='center'>
                        <Button variant={twoStepAuth?.securityVerification ? 'contained' : 'outlined'} fullWidth
                            disableElevation color={twoStepAuth?.securityVerification ? 'success' : 'info'}
                            onClick={(e) => twoStepAuth?.securityVerification ? e.stopPropagation() : updateSecAuth('QUESTION_ANSWER')}>
                            <Stack>
                                <Typography color={twoStepAuth?.securityVerification ? 'white' : 'inherit'} textTransform='none'
                                    fontWeight={twoStepAuth?.securityVerification ? 600 : 500}>
                                    {twoStepAuth?.securityVerification ? '' : 'Add'} Security Question
                                </Typography>
                                <Typography textTransform='none' variant='caption' color={twoStepAuth?.securityVerification ? 'white' : 'inherit'}>
                                    {twoStepAuth?.securityVerification ? 'Answer the Security Question to Login.' :
                                        'Set a security question to enable this feature.'}
                                </Typography>
                            </Stack>
                            {twoStepAuth?.securityVerification ? <Typography color='white'><CheckIcon /></Typography> : <></>}
                        </Button>
                        <ShTooltip
                            title="Security questions to add an extra layer of protection to your account." placement="right">
                            <HelpOutlineIcon fontSize="small" color='disabled' /></ShTooltip>
                    </Stack>
                    {twoStepAuth?.authQuestionId && <ShButton size='small' onClick={openSecQstnModal}>
                        <Typography textTransform='none'>Change Security Question</Typography>
                    </ShButton>}
                    <Divider />
                    <Stack columnGap={1} flexDirection='row' alignItems='center'>
                        <Button variant={twoStepAuth?.emailVerification ? 'contained' : 'outlined'} fullWidth disableElevation
                            color={twoStepAuth?.emailVerification ? 'success' : 'info'}
                            onClick={(e) => twoStepAuth?.emailVerification ? e.stopPropagation() : updateSecAuth('EMAIL_CODE')}>
                            <Stack>
                                <Typography color={twoStepAuth?.emailVerification ? 'white' : 'inherit'} textTransform='none'
                                    fontWeight={twoStepAuth?.emailVerification ? 600 : 500}>Email me the Code</Typography>
                                <Typography variant='caption' textTransform='none'
                                    color={twoStepAuth?.emailVerification ? 'white' : 'inherit'}>
                                    Get the Security Code to the registered Email.
                                </Typography>
                            </Stack>
                            {twoStepAuth?.emailVerification ? <Typography color='white'><CheckIcon /></Typography> : <></>}
                        </Button>
                        <ShTooltip
                            title="Four digit code will be sent to your email to confirm your identity and protect your account."
                            placement="right"><HelpOutlineIcon fontSize="small" color='disabled' /></ShTooltip>
                    </Stack>
                    {(twoStepAuth?.securityVerification || twoStepAuth?.emailVerification) &&
                        <>
                            <Divider />
                            <ShButton color='error' endIcon={<ClearIcon />}
                                onClick={() => dispatch(updateSecurityAuth({
                                    security_varification: false,
                                    email_varification: false
                                }))}>Remove Two Step Authentication</ShButton>
                        </>
                    }
                </Stack>
            </Stack>
            {/* Set/Update security question dialog */}
            < AuthQuestionDialog authQuestionId={twoStepAuth?.authQuestionId}
                emailVerification={twoStepAuth?.emailVerification !== null ? twoStepAuth?.emailVerification : false}
                securityVerification={twoStepAuth?.securityVerification !== null ? twoStepAuth?.securityVerification : false}
                isAuthQstnDlgOpen={isAuthQstnDlgOpen}
                setIsAuthQstnDlgOpen={setIsAuthQstnDlgOpen} showNotification={showNotification}
                updateUserAuth={updateUserAuth} />
        </>
    );
}
