import { Badge, Box, Link } from "@mui/material";
import { IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { Link as RouterLink } from 'react-router-dom';
import { ShAvatar } from "shared/SharedStyles/ShDataDisplay";
import { stringToColor } from "shared/utils";
import { setBreadcrumbLabelRegistry, setBreadcrumbNavFrom } from "store/slices/app/breadcrumb-slice";
import { JobType } from "store/slices/employer/jobs/jobs-slice-model";
import { IJob } from "../JobsModel";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";

export const ApplicantsCount = ({ job, usedIn, jobsType }: { job: IJob, usedIn: 'Table' | 'List', jobsType: JobType }) => {

    const isXsScreen = IsXsScreen();
    const dispatch = useAppDispatch();
    const { paginationParams } = useAppSelector(state => state.employer.employerJobs.jobsList);

    const gotoApplicants = () => {
        dispatch(setBreadcrumbNavFrom({ breadcrumbNav: `${jobsType}_jobs` }));
        dispatch(setBreadcrumbLabelRegistry({
            labelReg: {
                [job.id]: `${job.name}`,
                [`jobs_${job.id}_page`]: `${paginationParams.requestedPageNumber}`,
                [`jobs_${job.id}_isExpand`]: 'false',
            }
        }));
    };

    return (<>
        {(job.new_stage_count !== undefined && job.new_stage_count > 0) ?
           <ShTooltip title="Applicants in 'New' stage" placement="top">
                <Link component={RouterLink} onClick={gotoApplicants} to={`/employer/applicants/${job.id}?stage=new`}>
                    <Badge color="primary" badgeContent={job.new_stage_count} overlap='circular'>
                        <ShAvatar height={isXsScreen ? 20 : 25} width={isXsScreen ? 20 : 25} backgroundColor={stringToColor(`${job.name}`)} />
                    </Badge>
                </Link>
            </ShTooltip>
            : (usedIn === 'List' && !isXsScreen) ? <Box height={30} width={30}></Box> : <></>
        }
    </>);
}