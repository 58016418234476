import CheckIcon from '@mui/icons-material/Check';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers/icons";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { ShSettingsIcon } from "assets/Icons";
import { IsMdScreen, IsXsScreen } from "helpers/hooks";
import { Link as RouterLink } from 'react-router-dom';
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShIconPrimary, ShIconSecondary } from "shared/SharedStyles/styleConstants";
import CommonQuestionSidebar from "../CommonQuestionSidebar";
import { users } from "../HelpConstants";

export const AccountCustomization = () => {

  const isMdScreen = IsMdScreen();
  const paddingValue = IsXsScreen() ? 1 : 3;


  return (
    <>
      <Stack padding={paddingValue} direction={'row'}>
        <ShContainer margin={'auto'}>
          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Account Customization</ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            To customize your account settings to better manage your recruitment process.
            Click the
            <ShButton component={RouterLink} to="/employer/settings/account/account-information"
              color='inherit' variant="text"
              startIcon={<ShSettingsIcon primaryColor={ShIconPrimary} secondaryColor={ShIconSecondary} />}>
              Settings
            </ShButton>
            Button.
            Update your personal and company information, manage users, and configure security settings.
          </ResourceTypography>

          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Account Information</ResourceTypography>
          <Box paddingTop={3} paddingBottom={3}>
            <Stack spacing={2}>
              <Typography variant="body1" paragraph>
                To update your account information, navigate to the <strong>"Account Information"</strong> section. Here, you can change your First Name, Last Name, and set preferences for receiving new applicant notifications.
              </Typography>
            </Stack>
          </Box>

          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Company Details</ResourceTypography>
          <Box paddingTop={3} paddingBottom={3}>
            <Stack spacing={2}>
              <Typography variant="body1" paragraph>
                In the <strong>"Company Details" section</strong>, you can update your company's information such as Company Name, Logo, Zipcode, Website, City, Phone Number, and Subdomain for career pages. Keeping this information current ensures accurate representation of your company on the platform.
              </Typography>
            </Stack>
          </Box>

          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Manage Users</ResourceTypography>
          <Box paddingTop={3} paddingBottom={3}>
            <Typography variant="body1" paragraph>
              The <strong>"Manage Users" </strong>section allows you to set roles for each of your users. You can view and update roles for all team members, ensuring they have the appropriate access and permissions.
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>All Jobs</TableCell>
                    <TableCell>Create</TableCell>
                    <TableCell>Assign</TableCell>
                    <TableCell>View</TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>{user.userName}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.allJobs ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}</TableCell>
                      <TableCell>{user.create ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}</TableCell>
                      <TableCell>{user.assign ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}</TableCell>
                      <TableCell>{user.view ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}</TableCell>
                      <TableCell>{user.comment ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center" justifyContent="center">
                          <MoreVertIcon />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Security</ResourceTypography>
          <Box paddingTop={3} paddingBottom={3}>
            <Stack spacing={2}>
              <Typography variant="body1" paragraph>
                In the <strong>"Security"</strong> section, you can view relevant security information and enable two-factor authentication for enhanced account protection.
              </Typography>
            </Stack>
          </Box>
        </ShContainer>
        {!isMdScreen && <CommonQuestionSidebar />}
      </Stack>
    </>
  );
};
