import { Settings } from "@mui/icons-material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from "@mui/icons-material/Menu";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Avatar, IconButton, ListItemIcon, ListItemText, Menu, Stack, Toolbar, Tooltip } from "@mui/material";
import { TopNavItemsContainerStyled } from "Modules/Core/Layouts/AppLayout/AppLayout.styled";
import { PricingInfo } from 'Modules/Core/Pricing/PricingInfo';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ShLogo } from "shared/SharedComponents/ShLogo";
import ThemeToggler from "shared/SharedComponents/ThemeToggler";
import { MobileOnlyStack } from "shared/SharedStyles/ShLayouts";
import { StyledMenuItem } from "shared/SharedStyles/ShNavigation";
import { ShAppBar } from "shared/SharedStyles/ShSurfaces";
import { getInitialsFromName, stringToColor } from "shared/utils";
import { togglePricingDialog } from 'store/slices/app/app-info-slice';
import { logoutAccount } from "store/slices/auth-v2/auth-v2-slice";
import { getEmployerDetails } from "store/slices/employer/employer/employer-details-slice";

export const EmployerTopNav = ({ setIsDrawerOpen }: { setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { employerId } = useAppSelector((state) => state.auth.auth);
    const { employerDetails } = useAppSelector(state => state.employer.employerDetails);
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLElement | null>(null);
    const isProfileMenuOpen = Boolean(profileMenuAnchorEl);
    const { firstName, lastName } = useAppSelector((state) => state.auth.auth);
    const userInitials = getInitialsFromName(`${firstName} ${lastName}` || "SK", true, true);
    const avatarBackgroundColor = stringToColor(userInitials);

    // Load employer info once logged in.
    useEffect(() => {
        if (employerId && !employerDetails) {
            dispatch(getEmployerDetails({ empId: employerId }));
        }
    }, [employerId, dispatch, employerDetails]);

    return (<>
        <ShAppBar position="sticky">
            <Toolbar>
                <Stack direction="row" alignContent="center" justifyContent="flex-start">
                    <MobileOnlyStack>
                        <IconButton size="large" edge="start" onClick={() => setIsDrawerOpen((prev) => !prev)}>
                            <MenuIcon />
                        </IconButton>
                    </MobileOnlyStack>
                    <ShLogo link="/employer/dashboard" />
                </Stack>
                <TopNavItemsContainerStyled>
                    <Tooltip title="Contact">
                        <RouterLink to="/employer/help/contact-support">
                            <IconButton size="large">
                                <SupportAgentIcon />
                            </IconButton>
                        </RouterLink>
                    </Tooltip>
                    <Tooltip title="Pricing">
                        <IconButton size="large" onClick={() => dispatch(togglePricingDialog())}>
                            <MonetizationOnOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Help">
                        <RouterLink to="/employer/help">
                            <IconButton size="large">
                                <HelpOutlineOutlinedIcon />
                            </IconButton>
                        </RouterLink>
                    </Tooltip>
                    {/* To be enabled once this functionality is implemented */}
                    {/* <Tooltip title="Notifications">
                    <IconButton size="large">
                        <Badge className='notif-badge' color='primary'>
                        <NotificationsOutlinedIcon />
                        </Badge>
                    </IconButton>
                    </Tooltip> */}
                    {/* Button to change the theme */}
                    <ThemeToggler />
                    <IconButton onClick={e => setProfileMenuAnchorEl(e.currentTarget)}>
                        {firstName ?
                            <Avatar style={{ backgroundColor: avatarBackgroundColor }}>
                                {firstName[0]?.toUpperCase()}
                            </Avatar> :
                            <PersonIcon />
                        }
                    </IconButton>
                </TopNavItemsContainerStyled>
                <MobileOnlyStack direction='row'>
                    {/* Button to change the theme */}
                    <ThemeToggler />
                    <IconButton onClick={e => setProfileMenuAnchorEl(e.currentTarget)}>
                        {firstName ?
                            <Avatar style={{ backgroundColor: avatarBackgroundColor }}>
                                {firstName[0]?.toUpperCase()}
                            </Avatar> :
                            <PersonIcon />
                        }
                    </IconButton>
                    {/* To be enabled once this functionality is implemented */}
                    {/* <IconButton size="large" edge="end">
                        <Badge className='notif-badge' color='primary'>
                            <NotificationsOutlinedIcon />
                        </Badge>
                    </IconButton> */}
                </MobileOnlyStack>
            </Toolbar>
            <PricingInfo />
            <Menu id="profile-settings-menu" aria-label='Toggle profile settings' anchorEl={profileMenuAnchorEl}
                open={isProfileMenuOpen} onClose={() => setProfileMenuAnchorEl(null)}>
                <StyledMenuItem onClick={() => { navigate('/employer/settings/account/account-information'); setProfileMenuAnchorEl(null) }}>
                    <ListItemIcon><Settings color="primary" /></ListItemIcon>
                    <ListItemText>Account settings</ListItemText>
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={() => dispatch(logoutAccount({ logoutType: 'user' }))}>
                    <ListItemIcon><LogoutIcon color="warning" /></ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </StyledMenuItem>
            </Menu>
        </ShAppBar>
    </>
    );
};
