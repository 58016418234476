import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Divider, FormControl, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import moment from "moment";
import { useRef, useState } from "react";
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn, ShRadioGroup } from "shared/SharedStyles/ShInputs";
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { resetGetCandidateInfoFromResume, updateCandidateResume } from "store/slices/candidate/apply-job/resume-slice";
import { ShFileUpload } from "./ApplyJob.styled";
import { IApplyJobUploadResume } from './ApplyJobModel';

export const ApplyJobResumeSelect = ({ selectedResume, setSelectedResume, setCurrentJobApplyStep,
    navigatedFrom, uploadResume }: IApplyJobUploadResume) => {

    const dispatch = useAppDispatch();
    const dropRef = useRef<HTMLDivElement>(null);
    const { candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);
    const [selectedResumeId, setSelectedResumeId] = useState<number>(0);

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        if (files.length > 0 && setSelectedResume) {
            setSelectedResume(files[0]);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(resetGetCandidateInfoFromResume());
        if (event.target.files && event.target.files.length > 0 && setSelectedResume) {
            setSelectedResume(event.target.files[0]);
            setSelectedResumeId(0);
        }
    };

    const selectPrevResume = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target as HTMLInputElement).value) {
            setSelectedResumeId(parseInt((event.target as HTMLInputElement).value));
            if (setSelectedResume)
                setSelectedResume(undefined);
        }
    };

    const handleResumeUpload = () => {
        if (selectedResume) {
            const formData = new FormData();
            formData.append('resume', selectedResume);
            if (uploadResume) {
                uploadResume(true);
            }
        } else {
            if (candidateDetails?.jobApplicationId) {
                dispatch(updateCandidateResume({ candidateId: candidateDetails?.jobApplicationId, resumeId: selectedResumeId }));
            }
        }
    };

    return (<>
        <ShContainer maxWidth='sm' margin='auto'>
            <Stack rowGap={1} marginBottom={1}>
                <Typography variant='body1' textAlign='center' gutterBottom>
                    Select an existing resume
                </Typography>
                <FormControl>
                    <ShRadioGroup
                        aria-labelledby="resume-selection"
                        name="resume-selection-group"
                        value={selectedResumeId} onChange={selectPrevResume}>
                        {candidateDetails?.previousResumes.map(resume => (
                            <FormControlLabel key={resume.id} id={resume.file_name} aria-label={resume.file_name}
                                className={selectedResumeId === resume.id ? 'selected' : ''}
                                labelPlacement="start" value={resume.id}
                                control={<Radio />}
                                label={
                                    <Stack>
                                        <Typography variant="body1">
                                            {resume.file_name}
                                        </Typography>
                                        <Typography variant="caption">
                                            Uploaded at {moment(resume.date).format("MMM DD, YYYY")}
                                        </Typography>
                                        <ShMuiLink href={resume.signedUrl} color='primary'
                                            underLineOnHover noUnderline target='_blank'>
                                            <Typography variant='body2'>view Resume</Typography>
                                        </ShMuiLink>
                                    </Stack>
                                } />
                        ))}
                    </ShRadioGroup>
                </FormControl>
            </Stack>
            <Divider>Or</Divider>
            <Stack rowGap={1} alignItems='center' marginTop={1}>
                <Typography variant="body1" textAlign='center'>Upload new Resume</Typography>
                <ShFileUpload ref={dropRef} onDragEnter={handleDragEnter} onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave} onDrop={handleDrop} >
                    <input type="file" accept=".doc, .docx, .txt, .rtf, .pdf" id="file-input"
                        onChange={handleFileChange} />
                    <label htmlFor="file-input">
                        <ShButton startIcon={<UploadFileIcon />} component="span">
                            <Typography variant="body2" textTransform='none'>
                                {selectedResume?.name ? selectedResume.name : 'Drag and drop or browse files'}
                            </Typography>
                        </ShButton>
                    </label>
                </ShFileUpload>
            </Stack>
            {(selectedResume || selectedResumeId > 0) &&
                <ShAlert marginTop={2} severity='info'>Resume attached to the job application successfully</ShAlert>}
            <Stack direction='row' justifyContent='space-between' width='100%' marginTop={2}>
                {setCurrentJobApplyStep &&
                    <ShButton variant='outlined' onClick={() => {
                        setCurrentJobApplyStep('email');
                        setSelectedResume && setSelectedResume(undefined)
                    }}>Back</ShButton>}

                <ShGreenBtn size='small' disabled={!selectedResume && !selectedResumeId}
                    variant="outlined" onClick={handleResumeUpload}>Next</ShGreenBtn>
            </Stack>
        </ShContainer>
    </>);
}