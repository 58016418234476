import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { REACT_APP_BASE_URL } from "configs/EnvConfig";
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { IAllJobDetails, IJobInfoState } from './jobs-slice-model';

const initialJobsInfoState: IJobInfoState = {
    getJobDetailsByCodeResponse: '',
    getJobDetailsByCodeStatus: 'idle',
    getJobDetailsByFallbackApiStatus: 'idle',
    getJobDetailsStatus: 'idle',
    getJobDetailsResponse: '',
};


export const getJobDescriptionByCode = createAsyncThunk<IBaseResponse, { jobCode: string }, { rejectValue: IBaseResponse }>(
    "getJobDescriptionByCode",
    async ({ jobCode }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`/candidate/apply-job/${jobCode}`, {
                baseURL: REACT_APP_BASE_URL,
            })
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getJobDescriptionByCodeFallback = createAsyncThunk<IBaseResponse, { jobCode: string }, { rejectValue: IBaseResponse }>(
    "getJobDescriptionByCodeFallBack",
    async ({ jobCode }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOB_DETAILS_BY_CODE}/${jobCode}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getJobDetails = createAsyncThunk<IBaseResponse<IAllJobDetails>, { jobId: number }, { rejectValue: IBaseResponse }>(
    "getJobDetails",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS}/${jobId}`)
            .then((response: AxiosResponse<IBaseResponse<IAllJobDetails>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const jobInfoSlice = createSlice({
    name: 'jobInfo',
    initialState: initialJobsInfoState,
    reducers: {
        resetGetJobDescription: (state) => { state.getJobDetailsByCodeResponse = ''; state.getJobDetailsByCodeStatus = 'idle' },
        resetGetJobDetails: (state) => {
            state.getJobDetailsStatus = 'idle';
            state.getJobDetailsResponse = '';
            state.jobDetailsInInfo = undefined
        },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        // builder.addCase(PURGE, () => {
        //     return initialJobsInfoState;
        // });
        // get job desc
        builder.addCase(getJobDescriptionByCode.pending, (state) => {
            state.getJobDetailsByCodeStatus = 'pending';
        });
        builder.addCase(getJobDescriptionByCode.fulfilled, (state, action) => {
            state.getJobDetailsByCodeStatus = 'success';
            state.jobDetailsByCode = action.payload.data;
            state.getJobDetailsByCodeResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(getJobDescriptionByCode.rejected, (state, action) => {
            state.getJobDetailsByCodeStatus = 'failed';
            state.getJobDetailsByCodeResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get job getJobDescription
        builder.addCase(getJobDescriptionByCodeFallback.pending, (state) => {
            state.getJobDetailsByFallbackApiStatus = 'pending';
        });
        builder.addCase(getJobDescriptionByCodeFallback.fulfilled, (state, action) => {
            state.getJobDetailsByFallbackApiStatus = 'success';
            state.jobDetailsByCode = action.payload.data;
            state.getJobDetailsByCodeResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(getJobDescriptionByCodeFallback.rejected, (state, action) => {
            state.getJobDetailsByFallbackApiStatus = 'failed';
            state.getJobDetailsByCodeResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get job details
        builder.addCase(getJobDetails.pending, (state) => {
            state.getJobDetailsStatus = 'pending';
        });
        builder.addCase(getJobDetails.fulfilled, (state, action) => {
            state.getJobDetailsStatus = 'success';
            state.jobDetailsInInfo = action.payload.data;
        });
        builder.addCase(getJobDetails.rejected, (state, action) => {
            state.getJobDetailsStatus = 'failed';
            state.getJobDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetGetJobDescription, resetGetJobDetails } = jobInfoSlice.actions;
export default jobInfoSlice;
