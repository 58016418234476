import { Drawer, Link, LinkProps, MenuItem, Tabs, TabsProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DarkModeBackground, PrimaryThemeColor, ShBorderRadius, ShOnHover, WhiteColor } from 'shared/SharedStyles/styleConstants';


const drawerWidth = 250;

export const ShDrawer = styled(Drawer)(
    ({ theme }) => ({
        width: drawerWidth,
        '& .MuiPaper-root': {
            width: drawerWidth,
        }
    })
);

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.selected-item': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.contrastText
        }
    }
}));


interface IShTabs extends TabsProps {
    highlightVariant?: 'underline' | 'button',
    highlightColor?: string,
    fontSize?: 'large' | 'medium' | 'small' | string,
    fontWeight?: 'bold' | number | string
}

export const ShTabs = styled(Tabs, {
    shouldForwardProp: (prop) => prop !== "highlightVariant" &&
        prop !== "highlightColor" && prop !== "fontSize" && prop !== "fontWeight"
})<IShTabs>(
    ({ theme, highlightVariant = 'button', fontSize = '14px',
        fontWeight = 'inherit', highlightColor = PrimaryThemeColor }) => ({
            minHeight: 'unset',
            '& .MuiTabs-indicator': {
                display: highlightVariant === 'button' ? 'none' : 'block',
                backgroundColor: highlightColor
            },
            '& .MuiTab-root': {
                minHeight: 'unset',
                fontSize: fontSize,
                fontWeight: fontWeight,
                minWidth: 'unset',
                borderRadius: ShBorderRadius,
                padding: theme.spacing(1),
                textTransform: 'none',
                '&.Mui-selected': {
                    backgroundColor: highlightVariant === 'button' ? highlightColor : 'inherit',
                    color: highlightVariant === 'button' ? theme.palette.getContrastText(highlightColor) : 'inherit',
                    '& .check-icon': {
                        color: theme.palette.mode === 'light' ? WhiteColor : DarkModeBackground
                    }
                }
            },
            '&.applicant-tabs': {
                width: 'fit-content'
            },
            '&.create-job-tabs': {
                pointerEvents: 'none'
            }
        })
);

interface IShMuiLink extends Omit<LinkProps, 'component'> {
    noUnderline?: boolean,
    noBlueHighlight?: boolean,
    color?: string,
    transElevateOnHover?: boolean,
    component?: React.ElementType;
    to?: string;
    underLineOnHover?: boolean,
    outlined?: boolean,
    width?: string
}

export const ShMuiLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== "noUnderline" && prop !== "noBlueHighlight" && prop !== "width" &&
        prop !== "color" && prop !== "transElevateOnHover" && prop !== "underLineOnHover" && prop !== "outlined"
})<IShMuiLink>(({ theme, noUnderline, noBlueHighlight, color = 'navy', width = 'fit-content',
    transElevateOnHover = false, underLineOnHover = false, outlined }) => ({
        width: width,
        textDecoration: noUnderline ? 'none' : 'underline',
        cursor: 'pointer',
        color: noBlueHighlight ? theme.palette.text.primary : color,
        border: outlined ? `1px solid ${noBlueHighlight ? theme.palette.text.primary : color}` : 'none',
        padding: outlined ? theme.spacing(1) : '',
        borderRadius: transElevateOnHover || outlined ? ShBorderRadius : 0,
        ...(ShOnHover(transElevateOnHover, theme, (underLineOnHover ? { textDecoration: 'underline' } : {}))),
        '&.disabled': {
            pointerEvents: 'none',
            color: 'inherit'
        }
    }));