import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ShBanner = styled(Box)(
    ({ theme }) => ({
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        justifyContent: 'center'
    })
);